import { useEffect } from "react";
import config  from "../config";
import { generateRandomString } from './generateRandomString';

import pkceChallenge from 'pkce-challenge'

//  const createPkceChallenge = async () => {
//   const { code_verifier, code_challenge } = await pkceChallenge(128);
//   localStorage.setItem('tidal_pkce_code_verifier', code_verifier);
//   return code_challenge
// };


const APP_URL = config.appUrl;


const RedirectTidal = () => {
    useEffect(async () => {
      await pkceChallenge(128).then(({ code_verifier, code_challenge }) => {

        localStorage.setItem('tidal_pkce_code_verifier', code_verifier);

        window.location.href = "https://login.tidal.com/authorize"
        + "?response_type=code"
        + "&client_id=zQOYQ3lSAZ8A8iv8"
        + "&scope=user.read collection.read collection.write playlists.read playlists.write"
        + "&redirect_uri=" + APP_URL + "/connect/tidal/authenticate"
        + "&code_challenge_method=S256"
        + "&code_challenge=" + code_challenge
        + "&state=" + generateRandomString(16)
      })
        
    }, []);
  
    return <h5>Redirecting...</h5>;
  };

export default RedirectTidal;

import  SpotifyIcon  from "./assets/Spotify_Icon_RGB_Green.png";
import  TidalIcon  from "./assets/tidal_logo_black.png";
import  AppleMusicIcon  from "./assets/appleMusic.png";
import   YoutubeIcon  from "./assets/youtube_social_icon_red.png";
import YoutubeMusicIcon  from "./assets/youtube-music-icon.png";

import { format, parseISO } from 'date-fns';
import { Typography } from "@mui/material";
import pkceChallenge from 'pkce-challenge'


export const  getIconForTransfer = (type, isYoutubeMusic=false) => {
    if (type === "spotify") {
      return <span style={{width:40, height:26}}><img  src={SpotifyIcon} style={{height:26}} alt="SpotifyIcon"/></span>
    }
    if (type === "tidal") {
      return <span style={{width:40, height:26}}><img  src={TidalIcon} style={{height:26}} alt="TidalIcon"/></span>
    }
    if (type === "appleMusic") {
      return  <span style={{width:40, height:26}}><img src={AppleMusicIcon} style={{height:24}} /></span>
    }
    if (type === "youtube") {
      if(isYoutubeMusic) {
        return  <span style={{width:40, height:26}}><img src={YoutubeMusicIcon} style={{height:24}} /></span>
      }
      return  <span style={{width:40, height:26}}><img src={YoutubeIcon} style={{height:23}} /></span>
    }
  }

  export const  getIconForTransferUI = (type, isYoutubeMusic) => {

    if (type === "spotify") {
      return <span style={{width:40, height:26}}><img  src={require('./assets/Spotify_Icon_RGB_Green.png')} style={{height:26}} alt="SpotifyIcon"/></span>
    }
    if (type === "tidal") {
      return <span style={{width:40, height:26}}><img  src={require('./assets/tidal_logo_black.png')} style={{height:26}} alt="TidalIcon"/></span>
    }
    if (type === "appleMusic") {
      return  (<span style={{width:40, height:26}}><img src={require('./assets/appleMusic.png')} style={{height:24}} /></span>)
    }
    if (type === "youtube") {
      if(isYoutubeMusic) {
        return  <span style={{width:40, height:26}}><img src={require('./assets/youtube-music-icon.png')} style={{height:24}} /></span>
      }
      return  <span style={{width:40, height:26}}><img src={require('./assets/youtube_social_icon_red.png')} style={{height:23}} /></span>
    }
  }

  export const getCaTypetoName = (type, isYoutubeMusic) => {
    switch (type) {
      case 'spotify':
        return 'Spotify';
      case 'appleMusic':
        return 'Apple Music';
      case 'youtube':
        if(isYoutubeMusic === true) {
          return "YouTube Music"
        }
        return 'YouTube';
      default:
        return 'Music account';
    }
  }


  export const getThumbnailUrlCleaned = (url, accountType) => {
    let thumbnail =""

    if(accountType === 'appleMusic' && "" != url) {
      thumbnail = url.replace("{w}", "48")
      thumbnail = thumbnail.replace("{h}", "48")
      return thumbnail
    }
    return url

  }



import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Logout = () => {
    const navigate = useNavigate();
  

  useEffect(() => {
    const logout = async () => {
      localStorage.clear();

      // Redirect to home screen
      navigate('/');
    };

    logout();
  }, []);

  return null; // This component does not render anything
};

export default Logout;
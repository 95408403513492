const config = {
     apiUrl: 'https://api.musicporter.com/api', 
    appUrl: 'https://app.musicporter.com', 
    stripePublicKey:'pk_live_51KmsS5BthWZg44kQThrLO4wdubXZ47n0HhAdZTrsGdKnsVICD07yx9a5VS7j5shss13pCN2miHmmU7EFNnPENo4900bHk14GeE',//PROD

    // apiUrl: 'http://localhost:8080/api',
    // appUrl: 'http://localhost:3000',
    // stripePublicKey:'pk_test_51KmsS5BthWZg44kQ7EECKRCTNgN6cbwHudLIllTrUoBrXkD2RxK7qN9991t398sP3Ta4vT0amGkoRh6rZf6oMBwK00g6Ztm8v1', //Test

    loginPath: '/login',
    dashboardPath: '/dashboard',
    accountsPath: '/dashboard/accounts',
    transferHistoryPath: '/dashboard/transferHistory',
    appleDeveloperToken:"eyJraWQiOiJMWU1GQUE3WExUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJTWlJRSzlaWTVNIiwiaWF0IjoxNzIzMDQ5Mzk3LCJleHAiOjE3MzA4MjUzOTd9.fWtn8d_teMUFGGqApfWa7kHxH69dGcHAMhj1g8AQdlTnWAJkWdvlBQ-DNk-9ZfMIbkuM-4VdmZroTxVO0Aa2tA",
    googleAuthClientId: "52445584591-b97rjj6d3ggmpniqt8jrvt68i1uj1krl.apps.googleusercontent.com" 
  };


  
  export default config;
import React, { useState, useEffect } from 'react';
import config from '../../config';
import { useNavigate } from 'react-router-dom';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import {getIconForTransfer} from './../../utils'

import AddNewAccount from './addNewAccount';



export default function AccountsDetails(props) {


    const [hasEnoughAccounts, setHasEnoughAccounts] = useState(props.connectedAccounts==null?false:props.connectedAccounts.length>0);

    const [connectedAccounts, setConnectedAccounts] = useState(props.connectedAccounts)

    const navigate = useNavigate();





    const handleCurrentAccountSelectionChange = (event) => {
        props.onSelectedAccountChange(event.target.value);
      };
    
    
    function AccountSelector() {
        return (
            <>
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id="demo-simple-select-helper-label">{props.defaultSelectText}</InputLabel>
                    <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={props.selectedAccount}
                        label="Select Music Account"
                        onChange={handleCurrentAccountSelectionChange}
                        defaultValue={props.selectedAccount}
                    >
                        {/* <MenuItem value="">
                             {getIconForTransfer("appleMusic")}  <span>&nbsp;Apple Music</span>
                        </MenuItem> */}

                        {
                            props.connectedAccounts.map((account) => {
                                if(account.accountType === "spotify") {
                                    return (<MenuItem value={account.id} key={account.id}>{getIconForTransfer(account.accountType, false)} &nbsp; {account.profileName}</MenuItem>)
                                }
                            })
                        }
{/* 
                        <MenuItem value="">
                            <em>Spotify</em>
                        </MenuItem> */}
                        {
                            props.connectedAccounts.map((account) => {
                                if(account.accountType === "appleMusic") {
                                    return (<MenuItem value={account.id} key={account.id}>{getIconForTransfer(account.accountType, false)}&nbsp;{account.profileName}</MenuItem>)
                                }
                            })
                        }
                                                
                        {
                            props.connectedAccounts.map((account) => {
                                if(account.accountType === "youtube") {
                                    return (<MenuItem value={account.id} key={account.id}>{getIconForTransfer(account.accountType, account.isYoutubeMusic)}&nbsp;{account.profileName}</MenuItem>)
                                }
                            })
                        }
                    </Select>
                </FormControl>
            </>
        );
    }



    return (
        
        <>
            { hasEnoughAccounts ?
                    (<AccountSelector></AccountSelector>)
                    : (<></>)
            }
        </>
    )
}
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { useLocation, useNavigate } from 'react-router-dom';
import { Route, Routes } from 'react-router-dom';

import ConnectedAcccounts from './transfer/connectedAcccounts'
import Logout from './logout';
import TransferHistory from './transfer/history/transferHistory'
import CompleteManualTransfer from './transfer/manual/completeManualTransfer'
import Profile from './profile'
import Backup  from './backup/backup';
import PremiumOptions from './premium/premiumOptions'
import SyncMain from './sync/syncMain'
import EditTransfer from './transfer/history/editTransfer'
function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://musicporter.com.com/">
        Music Porter
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}


export default function Main() {

  const location = useLocation();



  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>

    <Grid container spacing={3}>
      <Routes>
        <Route path='/accounts' element={<ConnectedAcccounts />} ></Route>
        <Route path='/sync' element={<SyncMain />} ></Route>
        <Route path='/transferHistory' element={<TransferHistory />} ></Route>
        <Route path='/transferHistory/:transferId/edit' element={<EditTransfer />} ></Route>
        <Route path='/transferHistory/:transferId/complete' element={<CompleteManualTransfer />} ></Route>
        <Route path='/profile' element={<Profile />} ></Route>
        <Route path='/backups' element={<Backup />} ></Route>
        <Route path='/premium' element={<PremiumOptions />} ></Route>
        <Route path='/logout' element={<Logout />} ></Route>
      </Routes>
    </Grid>
            
      
      <Copyright sx={{ pt: 4 }} />


    </Container>
  )
}
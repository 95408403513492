import React, {useEffect} from 'react';
import { useLocation,useNavigate, useParams } from 'react-router-dom';
import config from '../config';

export default function EmailHandler(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const { token, userId } = useParams();

    const activateAccount = () => {
        fetch(config.apiUrl + "/user/activateAccount?activationToken="+token+"&id="+userId, { 
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (!response.ok) {
                    navigate(config.loginPath+'?message=ACTIVATION_FAILED')
                }
            })
            .then(data => {
                navigate(config.loginPath+'?message=ACTIVATION_SUCCESS')
            })
            .catch((error) => {
                navigate(config.loginPath+'?message=ACTIVATION_FAILED')
            });
    }

    useEffect(() => {
              if(props.action === "activateAccount") {
                activateAccount();
              } else if(props.action === "updatePassword") {
                window.location.href = config.appUrl + "/resetPassword/" + userId + "/" + token;
              }
      }, []);

    

    return (
        <div>


        </div>
    );
}
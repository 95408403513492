import {React, useState, useEffect} from 'react';
import config from '../../config';
import { useNavigate } from 'react-router-dom';


const AmazonMusicCallback = () => {
  // Parse the callback URL parameters to retrieve the authentication code
  const queryString = window.location.search
  const params = new URLSearchParams(queryString);
  const navigate = useNavigate();

  useEffect(() => {
    const apiUrl = `${config.apiUrl}/amazonMusic/auth/callback/internal?code=${params.get('code')}`;

    const user = JSON.parse(localStorage.getItem('user'));
    fetch(apiUrl, {
            headers: {
                'Authorization': 'Bearer ' + user.accessToken,
            }
        })
      .then(response => {
            if (!response.ok) {
                navigate(config.accountsPath+'?message=AMAZON_ADD_ERROR')
            }
            return response.json();
      })
      .then(data => {
          navigate(config.accountsPath);
      })
      .catch(error => {
        navigate(config.accountsPath+'?message=AMAZON_ADD_ERROR')
    });
  }, []);


  // Make a request to your server to exchange the code for an access token
  // For security reasons, this exchange should be done on the server-side

  return (
    <div>
    </div>
  );
};

export default AmazonMusicCallback;
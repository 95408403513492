import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    IconButton,
    Box,
} from '@mui/material';

import PremiumIcon from '../../assets/premium.png'

import CloseIcon from '@mui/icons-material/Close';
import StarIcon from '@mui/icons-material/Star';
import config from '../../config';

export default function PremiumPopup (props) {
  const [open, setOpen] = useState(props.showPremiumOptions);

  const handleClose = () => {
    setOpen(false)
    props.setShowPremiumOptions(false);
  };

    const handleUpgrade = () => {
        window.location.href = config.dashboardPath + "/premium"
  };

  return (
<Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">
            Beyond 199 tracks..
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
          <Typography variant="h4" gutterBottom align="center">
              Upgrade Premium          
          </Typography>
        <Typography variant="subtitle1" align="center" gutterBottom>
          You tried to transfer <strong>{props.triedTracksCount} </strong>tracks and albums. You can transfer upto <strong>199</strong> tracks for free.
        </Typography>
        <Box display="flex" justifyContent="center" my={2}>
          <img
            src={PremiumIcon} // Replace with your image URL
            alt="Diamond"
            style={{ width: '100px', height: '100px' }}
          />
        </Box>
        <Typography variant="body2" align="center">
          Go premium just for <strong>$1.99</strong> for 30 days
        </Typography>
        <Typography variant="body2" align="center">
           <strong>no subscription</strong>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button 
          variant="contained" 
          color="primary" 
          fullWidth
          onClick={handleUpgrade}
          sx={{ margin: 2 }}
        >
          Go Premium for $1.99 !!
        </Button>
      </DialogActions>
    </Dialog>
  );
};

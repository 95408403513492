import { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Alert } from '@mui/material';
import { useGoogleLogin } from '@react-oauth/google';
import GoogleButton from 'react-google-button'

import { useNavigate, redirect } from 'react-router-dom';
import config from '../config';

export function Terms(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'By proceeding, you agree to '}
      <Link color="inherit" sx={{ fontWeight: 'bold', textDecoration: 'underline' }} href="https://musicporter.com/privacy-and-terms/">
        our terms of service and privacy policy 
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://musicporter.com/">
        Music Porter
      </Link>{' '}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function SignUp() {



  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [errResponse, setErrResponse] = useState("");

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
    acceptedTerms: true
  });

  ;

  const validate = (submittedFormData) => {
    let errors = {};

    if (!submittedFormData.name) {
      errors.name = "Name is required";
    }

    if (!submittedFormData.email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(submittedFormData.email)) {
      errors.email = "Email address is invalid";
    }

    if (!submittedFormData.password) {
      errors.password = "Password is required";
    } else if (submittedFormData.password.length < 6) {
      errors.password = "Password must be at least 6 characters";
    }

    if (!submittedFormData.confirmPassword) {
      errors.confirmPassword = "Confirm password is required";
    } else if (submittedFormData.confirmPassword !== submittedFormData.password) {
      errors.confirmPassword = "Passwords do not match";
    }

    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    var formDataObj = new FormData(e.currentTarget);
    var newData = {
      name: formDataObj.get('name'),
      email: formDataObj.get('email'),
      password: formDataObj.get('password'),
      confirmPassword: formDataObj.get('confirmPassword'),
      acceptedTerms: true
    }
    setFormData(newData)
    const validationErrors = validate(newData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});
      fetch(config.apiUrl + "/user/create", { // Replace with your actual endpoint
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(newData)
      })
        .then(response => {
          if (!response.ok) {
            if (response.status === 400) {
              return response.text().then(text => {
                throw new Error(text || 'Something went wrong');
              });
            }
            throw new Error('Sorry, some error occured. Please try again later or let us know');
          }
          return response.text();
        })
        .then(data => {
          setErrResponse("Thank you for registering. Please check your inbox to confirm email.");
          setFormData({})
          setErrors({});
        })
        .catch((error) => {
          setErrResponse(error.message)
          setErrors({});
          console.error('Error:', error);
        });
    }
  };

  const googleLogin = useGoogleLogin({
    onSuccess: async tokenResponse => {
      navigate("/login/google/authenticate?code=" + tokenResponse.access_token)
    },
    // flow: 'implicit', // implicit is the default
  });

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box
            component="img"
            sx={{
              width: 350
            }}
            alt="Music Porter logo."
            src="https://musicporter.com/wp-content/uploads/2024/05/music-porter-high-resolution-logo-transparent-1.png?auto=format&w=350&dpr=2"
            mb={5}
          />



          <GoogleButton onClick={() => googleLogin()} label="Sign up with Google"></GoogleButton>


          <Typography component="h1" variant="h5">
            or
          </Typography>
          <Typography component="h1" variant="h5" >
            Sign up
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  autoComplete="given-name"
                  name="name"
                  required
                  fullWidth
                  id="name"
                  label="Name"
                  autoFocus
                />
                {errors.name && <Alert severity="error" icon={false} >{errors.name}</Alert>}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                />
                {errors.email && <Alert severity="error" icon={false} >{errors.email}</Alert>}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                />
                {errors.password && <Alert severity="error" icon={false} >{errors.password}</Alert>}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="confirmPassword"
                  label="Confirm password"
                  type="password"
                  id="confirmPassword"
                  autoComplete="confirmPassword"
                />
                {errors.confirmPassword && <Alert severity="error" icon={false} >{errors.confirmPassword}</Alert>}
              </Grid>

              <Grid item xs={12}>
                {/* <FormControlLabel
                  control={<Checkbox value="allowExtraEmails" color="primary" />}
                  label="I want to receive inspiration, marketing promotions and updates via email."
                /> */}
                {errResponse && <Alert severity="error" icon={false} >{errResponse}</Alert>}

              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign Up
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="login" variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Terms sx={{ mt: 5 }} />
        <Copyright sx={{ mt: 2 }} />
      </Container>
    </ThemeProvider>
  );
}
import React, { useEffect, useState } from 'react';
import Navbar from './navbar/navbar.js';
import { useLocation, useNavigate } from 'react-router-dom';
// import SpotifyHome from './spotify/spotifyHome';
// import YoutubeHome from './youtube/youtubeHome'
// // import RedirectUrl from '../helpers/redirectUrl';
// import TransferHome from './transfer/transferHome';
// import ViewTransfers from './transfer/viewTransfers'
// import Logout from './logout';
import config from '../config';
// import Profile from './profile/profile';
// import AppleMusicHome from './appleMusic/appleMusicHome'

export default function Dashboard() {
    const location = useLocation();
    const navigate = useNavigate();
    const [showingMobileNav, setShowingMobileNav] = useState(false)

    useEffect(() => {

        if (localStorage.getItem("user") == null || localStorage.getItem("logggedInAt") == null) {
            //Validate user token and route to dashboard, else clean usera
            navigate(config.loginPath);
        }

        //Short time to handle premium expiry, as there is no frequent update. Maybe check it once in a while and extend the expiry??
    
        const differenceInMs = Math.abs(new Date() - new Date(localStorage.getItem("logggedInAt")))
        if ((differenceInMs) / (1000 * 60 * 60) > 22) {
            localStorage.removeItem('user');
            localStorage.removeItem('logggedInAt');
            navigate(config.loginPath)
        }
    }, []);


    return (
        <div>
            <Navbar/>
        </div>
    );
}
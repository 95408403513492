import {React, useState, useEffect} from 'react';
import config from '../config';
import { useNavigate } from 'react-router-dom';


const GoogleLoginCallback = () => {
  // Parse the callback URL parameters to retrieve the authentication code
  const queryString = window.location.search
  const params = new URLSearchParams(queryString);
  const [token, setToken] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {

    //type=token, code when we dont use google library for login
    const apiUrl = `${config.apiUrl}/auth/google/callback/internal?type=token&code=${params.get('code')}`;

    fetch(apiUrl)
      .then(response => {
            if (!response.ok) {
                navigate(config.loginPath+'?message=GAUTH_ERROR')
            }
            return response.json();
      })
      .then(data => {
        localStorage.setItem('user', JSON.stringify(data));
        localStorage.setItem('logggedInAt', new Date().toISOString());
        navigate(config.accountsPath)
      })
      .catch(error => {
        navigate(config.loginPath+'?message=GAUTH_ERROR')
    });
  }, []);


  // Make a request to your server to exchange the code for an access token
  // For security reasons, this exchange should be done on the server-side

  return (
    <div>

    </div>
  );
};

export default GoogleLoginCallback;
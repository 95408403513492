import {React, useEffect} from 'react';
import config from '../../config';
import { useNavigate } from 'react-router-dom';


const YoutubeMusicCallback = () => {
  // Parse the callback URL parameters to retrieve the authentication code
  const navigate = useNavigate();
  const queryString = window.location.search
  const params = new URLSearchParams(queryString);

  useEffect(() => {
    const apiUrl = `${config.apiUrl}/youtube/auth/callback/internal?isYoutubeMusic=true&code=${params.get('code')}`;

    const user = JSON.parse(localStorage.getItem('user'));
    fetch(apiUrl, {
            headers: {
                'Authorization': 'Bearer ' + user.accessToken,
            }
        })
      .then(response => {
            if (!response.ok) {
                  response.text().then(text => {
                    throw new Error(text || 'Something went wrong');
                });
                navigate(config.accountsPath+'?message=YOUTUBE_ADD_ERROR')
            }
            return response.json();
      })
      .then(data => {
        if(data.message === "NO_YOUTUBE_CHANNEL") {
          navigate(config.accountsPath+'?message=NO_YOUTUBE_CHANNEL')
          return
        }
          navigate(config.accountsPath+'?message=YOUTUBE_ADDED&addedAccount='+data.id);
      })
      .catch(error => {
        navigate(config.accountsPath+'?message=YOUTUBE_ADD_ERROR')
    });
  }, []);


  // Make a request to your server to exchange the code for an access token
  // For security reasons, this exchange should be done on the server-side

  return (
    <div>
    </div>
  );
};

export default YoutubeMusicCallback;
import { useEffect } from "react";

const RedirectUrl = ({ url }) => {
    useEffect(() => {
      window.location.href = url;
    }, [url]);
  
    return <h5></h5>;
  };

export default RedirectUrl;

import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';

import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormControlLabel from '@mui/material/FormControlLabel';
import CircularProgress from '@mui/material/CircularProgress';
import React, { useEffect, useState } from 'react';
import config from '../../config';
import { useNavigate } from 'react-router-dom';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Alert } from '@mui/material';
import Divider from '@mui/material/Divider';
import { Container, Grid, Typography } from '@mui/material';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';




export default function Library(props) {


  const [loading, setLoading] = useState(true); // State to track loading state
  const [playlists, setPlaylists] = useState([])
  const [albums, setAlbums] = useState([])
  const [likedCount, setLikedCount] = useState(0)
  const [selectedAccountType, setSelectedAccountType] = useState('')
  const [selectedPlaylists, setSelectedPlaylists] = useState({})
  const [selectedAlbums, setSelectedAlbums] = useState({})
  const [isLikedSelected, setLikedSelected] = useState(false)
  const [playlistsExpanded, setPlayListsExpanded] = useState(false)
  const [showTagetList, setShowTargetList] = useState(false)
  const navigate = useNavigate();

  const [playlistAccordionOpen, setPlaylistAccordionOpen] = React.useState(false);

  const [albumAccordionOpen, setAlbumAccordionOpen] = React.useState(false);

  const [isBetaUser, setBetaUser] = useState(JSON.parse(localStorage.getItem('user')).beta)

  const [totalConnectedAccounts, setTotalConnectedAccounts] = useState(props.connectedAccounts === null ? 0 : props.connectedAccounts.length)


  useEffect(() => {
    if (props.selectedAccount === '') {
      return
    }
    const fetchData = async () => {
      try {
        setLoading(true); // Set loading state to true
        const response = await fetch(config.apiUrl + "/connectedAccount/" + props.selectedAccount + "/playlists", {
          headers: {
            'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('user')).accessToken,
          }
        });

        const status = await response.status;
        if (status === 200) {
          let data = await response.json()
          setPlaylists(data["userPlaylistList"])
          setAlbums(data["userAlbums"])
          setLikedCount(data["likedSongsCount"])
          setSelectedAccountType(data["accountType"])
        } else {
          throw new Error('Failed to get playlists');
        }

      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false); // Set loading state to false after fetch completes
      }
    };
    fetchData(); // Call fetchData function when component mounts
  }, [props.selectedAccount]); // Empty dependency array ensures useEffect runs only once




  function disconnectAccountAndRefresh  (id) {
  
      //TODO: rewrite this shit and other fetch like this
      try {
        // Make PUT request with ID
        fetch(config.apiUrl + '/connectedAccount/' + id +'/disconnect', {
          method: 'PUT',
          headers: {
            'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('user')).accessToken
            // Add any necessary headers like authorization tokens here
          },
          // Add any request body if required
          body: JSON.stringify({}),
        })
        .then(response => {
              if (!response.ok) {
                navigate(config.accountsPath+'?message=FAILED_TO_DISCONNECT')
              }
              return response.text();
        })
        .then(data => {
          window.location.href = config.accountsPath;
      })
        .catch(error => {
          navigate(config.accountsPath+'?message=FAILED_TO_DISCONNECT')
      });
  
  
    } 
   catch (error) {
      console.error('Error connecting Apple music:', error);
   }
  }

  function isAllPlayListSelected() {
    let isAllSelected = true
    playlists.map((playlist) => {
      if (selectedPlaylists[playlist.id] === undefined || selectedPlaylists[playlist.id] === false) {
        isAllSelected = false
      }
    })
    return isAllSelected;
  }


  //playlist function

  const handlePlaylistSelectAllChange = (event) => {

    let bkpPl = { ...selectedPlaylists };
    playlists.map((playlist) => {
      bkpPl[playlist.id] = event.target.checked
    })


    setSelectedPlaylists(bkpPl)
  }

  function isPlaylistIndeterminate() {
    let hasTrue = false
    let hasFalse = false;
    playlists.map((playlist) => {
      if (selectedPlaylists[playlist.id] === undefined || selectedPlaylists[playlist.id] === false) {
        hasFalse = true
      }
      if (selectedPlaylists[playlist.id] === true) {
        hasTrue = true
      }
    })

    return hasFalse && hasTrue
  }


  function isPlayListSelected(id) {
    if (selectedPlaylists[id] === true) {
      return true
    }
    return false;
  }

  const handlePlaylistSelection = (key) => {

    let bkpPl = { ...selectedPlaylists };
    if (bkpPl[key] === undefined || selectedPlaylists[key] === false) {
      bkpPl[key] = true
    } else {
      bkpPl[key] = false
    }
    setSelectedPlaylists(bkpPl)
  }



  //albums function

  function isAllAlbumSelected() {
    let isAllSelected = true
    albums.map((album) => {
      if (selectedAlbums[album.id] === undefined || selectedAlbums[album.id] === false) {
        isAllSelected = false
      }
    })
    return isAllSelected;
  }



  const handleAlbumSelectAllChange = (event) => {

    let bkpPl = { ...selectedAlbums };
    albums.map((album) => {
      bkpPl[album.id] = event.target.checked
    })


    setSelectedAlbums(bkpPl)
  }

  function isAlbumIndeterminate() {
    let hasTrue = false
    let hasFalse = false;
    albums.map((album) => {
      if (selectedAlbums[album.id] === undefined || selectedAlbums[album.id] === false) {
        hasFalse = true
      }
      if (selectedAlbums[album.id] === true) {
        hasTrue = true
      }
    })

    return hasFalse && hasTrue
  }


  function isAlbumSelected(id) {
    if (selectedAlbums[id] === true) {
      return true
    }
    return false;
  }

  const handleAlbumSelection = (key) => {

    let bkpPl = { ...selectedAlbums };
    if (bkpPl[key] === undefined || selectedAlbums[key] === false) {
      bkpPl[key] = true
    } else {
      bkpPl[key] = false
    }
    setSelectedAlbums(bkpPl)
  }


  function isAnySelected() {
    if (isLikedSelected) {
      return true;
    }
    if (Object.values(selectedPlaylists).some(value => value === true)) {
      return true;
    }
    if (Object.values(selectedAlbums).some(value => value === true)) {
      return true;
    }
  }

  function disableTransferOption() {
    let val= (!isAnySelected() || props.connectedAccounts.length < 2)
    return val
  }

  function selectedAlbumSize() {
    let count = 0;
    Object.keys(selectedAlbums).map(function (keyName, keyIndex) {
      // use keyName to get current key's name
      // and a[keyName] to get its value
      if (selectedAlbums[keyName] === true) {
        count++
      }
    });
    return count
  }


  function selectedPlaylistSize() {
    let count = 0;
    Object.keys(selectedPlaylists).map(function (keyName, keyIndex) {
      // use keyName to get current key's name
      // and a[keyName] to get its value
      if (selectedPlaylists[keyName] === true) {
        count++
      }
    });
    return count
  }

  function Menu() {
    return (<div>

      <Box display="flex" justifyContent="flex-start" p={2}>

      <Button variant="contained" startIcon={<HighlightOffIcon />} onClick={() => { disconnectAccountAndRefresh(props.selectedAccount)}}>
          Disconnect
        </Button>
      </Box>

      <Box display="flex" justifyContent="flex-end" p={2}>

      {/* TODO disable on only one account connected */}
      <Stack direction="row" spacing={2}>
      <Button variant="contained" endIcon={<SendIcon />} disabled={disableTransferOption()} onClick={() => { props.setTransferData(getTransferData("automatic")); props.setShowTransferTargetMenu(true); }}>
          Start Transfer
        </Button>
        {isBetaUser? <><Button variant="contained" endIcon={<SendIcon />} disabled={disableTransferOption()} onClick={() => { props.setTransferData(getTransferData("manual")); props.setShowTransferTargetMenu(true); }}>
          Verify and Transfer
        </Button></>:<></>}

      </Stack>
      </Box>
      
      {totalConnectedAccounts === 1 ? <Alert severity="warning" sx={{m: .9}}>Connect atleast 2 accounts to start transfer.</Alert>:<></>}

      <Box>

        <Box
            sx={isLikedSelected?{backgroundColor: '#f5f5f5'}:{}}
          >
          <FormControlLabel
            label={likedCount + " saved tracks"}
            control={<Checkbox checked={isLikedSelected}
                onClick={() => setLikedSelected(!isLikedSelected)}
                disabled={likedCount === 0}
              />}
              sx={{ m: .9 }}
          />
        </Box>

        <Accordion expanded={playlistAccordionOpen}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon
              style={{ cursor: 'pointer' }}
              fontSize='large'
              onClick={() => setPlaylistAccordionOpen(!playlistAccordionOpen)} />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <FormControlLabel
              label={selectedPlaylistSize()+ "/" +playlists.length + " Playlists Selected"}
              control={
                <Checkbox
                  checked={isAllPlayListSelected() && playlists.length!=0}
                  indeterminate={isPlaylistIndeterminate()}
                  onChange={handlePlaylistSelectAllChange}
                  disabled={playlists.length===0}
                />
              }
            />
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ display: 'flex', flexDirection: 'column', ml: 1 }}>
              {

                playlists.map((playlist) => (
                  <Box key={playlist.id} sx={isPlayListSelected(playlist.id)?{  backgroundColor: '#f5f5f5' , pt: '1rem',}:{pt: '1rem'}}>
                    <FormControlLabel
                      label={getPlaylistTitleForView(playlist)}
                      key={playlist.id}
                      control={<Checkbox checked={isPlayListSelected(playlist.id)} onChange={() => handlePlaylistSelection(playlist.id)} />}
                    />
                    <Divider sx={{ mt: '1rem' }} />
                  </Box>
                  
                ))
              }
            </Box>
          </AccordionDetails>
        </Accordion>


        <>{selectedAccountType === "youtube" ? <><Alert severity="error" sx={{ m: 2 }}>Album transfer not supported for youtube.</Alert></>:<></>}</>

        <Accordion expanded={albumAccordionOpen}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon
              style={{ cursor: 'pointer' }}
              fontSize='large'
              onClick={() => setAlbumAccordionOpen(!albumAccordionOpen)} />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <FormControlLabel
              label={selectedAlbumSize()+ "/" +albums.length + " Albums Selected"}
              control={
                <Checkbox
                  checked={isAllAlbumSelected() && albums.length!=0}
                  indeterminate={isAlbumIndeterminate()}
                  onChange={handleAlbumSelectAllChange}
                  disabled={albums.length===0}
                />
              }
            />
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
              {
                albums.map((album) => (
                  <Box key={album.id} sx={isAlbumSelected(album.id)?{  backgroundColor: '#f5f5f5' , pt: '1rem',}:{pt: '1rem'}}>
                    <FormControlLabel
                      label={getAlbumTitleForView(album)}
                      key={album.id}
                      control={<Checkbox checked={isAlbumSelected(album.id)} onChange={() => handleAlbumSelection(album.id)} />}
                    />

                <Divider sx={{ mt: '1rem' }} />
                  </Box>
                ))
              }
            </Box>
          </AccordionDetails>
        </Accordion>

      </Box>

    </div>)
  }

  function getPlaylistTitleForView(playlist) {
    let thumbnail =""
    if(selectedAccountType === 'appleMusic' && playlist.thumbnailUrl != null) {
      thumbnail = playlist.thumbnailUrl.replace("{w}", "48")
      thumbnail = thumbnail.replace("{h}", 48)
    }

    return (          
    <Container sx={{ mt: '0.1rem', mb: '0.1rem' }}>
      <Grid container alignItems="center" spacing={2} >
        <Grid item>
          <img style={{width:48}} src={selectedAccountType === 'appleMusic' ? thumbnail : playlist.thumbnailUrl}  alt="" />
        </Grid>
        <Grid item xs>
          <Box>
            <Typography variant="body1" sx={{  
                fontWeight: 'bold'
             }} 
             gutterBottom >
               <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>{playlist.title}</div>
            </Typography>
            <Typography variant="span">
              {selectedAccountType === 'appleMusic' ? "" : playlist.itemsCount +" tracks" }
            </Typography>
          </Box>
        </Grid>
        <Grid item direction="row-reverse">
        </Grid>
      </Grid>
    </Container>)
  }

  function getAlbumTitleForView(album) {
    let thumbnail =""
    if(selectedAccountType === 'appleMusic' && album.thumbnailUrl != null) {
      thumbnail = album.thumbnailUrl.replace("{w}", "48")
      thumbnail = thumbnail.replace("{h}", 48)
    }

    return (     
      <Container sx={{ mt: '0.1rem', mb: '0.1rem' }}>
      <Grid container alignItems="center" spacing={2}>
        <Grid item>
          <img style={{width:48}} src={selectedAccountType === 'appleMusic' ? thumbnail : album.thumbnailUrl}  alt="" />
        </Grid>
        <Grid item xs>
          <Box>
            <Typography variant="body1" sx={{  
                fontWeight: 'bold'
             }} 
             gutterBottom >
              {album.title}
            </Typography>
            <Typography variant="span">
              {selectedAccountType === 'appleMusic' ? "" : album.itemsCount +" tracks" }
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Container>     
)
  }

  const getPlaylistName = (id) => {
    return playlists.find(playlist => playlist.id === id).title
  }

  const isAnyAlbumSelected = () => {
    let count = 0
    Object.keys(selectedAlbums).map(function (keyName, keyIndex) {
      if (selectedAlbums[keyName] === true) {
        count++;
      }
    })

    if (count > 0) {
      return true
    }
    return false
  }

  function getTransferData(transferMethod) {
    let createRequest = [];

    Object.keys(selectedPlaylists).map(function (keyName, keyIndex) {
      // use keyName to get current key's name
      // and a[keyName] to get its value
      if (selectedPlaylists[keyName] === true) {
        createRequest.push(
          {
            "transferMethod": transferMethod,
            "sourceCaId": props.selectedAccount,
            "sourcePlaylistId": keyName,
            "destinationPlaylistId": "string",
            "sourcePlaylistType": "playlist",
            "destinationPlaylistType": "playlist",//TODO: handle playlist type
            "playlistName": getPlaylistName(keyName),
            "songsIdToExclude": [

            ]
          }
        )
      }
    });

    if (isAnyAlbumSelected()) {
      var ids = []
      Object.keys(selectedAlbums).map(function (keyName, keyIndex) {
        // use keyName to get current key's name
        // and a[keyName] to get its value
        if (selectedAlbums[keyName] === true) {
          ids.push(keyName)
        }
      });

      createRequest.push(
        {
          "transferMethod": transferMethod,
          "sourceCaId": props.selectedAccount,
          "sourcePlaylistId": ids.join(","),
          "destinationPlaylistId": "",
          "sourcePlaylistType": "album",
          "destinationPlaylistType": "album",//TODO: handle playlist type
          "playlistName": "",
          "songsIdToExclude": [

          ]
        }
      )
    }

    if (isLikedSelected) {
      createRequest.push(
        {
          "transferMethod": transferMethod,
          "sourceCaId": props.selectedAccount,
          "sourcePlaylistId": "",
          "destinationPlaylistId": "",
          "sourcePlaylistType": "liked",
          "destinationPlaylistType": "liked",//TODO: handle playlist type
          "playlistName": "",
          "songsIdToExclude": [

          ]
        }
      )
    }
    return createRequest
  }


  return (

    <>
      {
        props.selectedAccount === '' ? <></> :
          loading ? <><CircularProgress /></> : <Menu></Menu>}
    </>


  );
}

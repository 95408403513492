import React, { useState, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import config from '../../config';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import CancelIcon from '@mui/icons-material/Cancel';
import SendIcon from '@mui/icons-material/Send';
import Alert from '@mui/material/Alert';

import {getIconForTransfer} from './../../utils'
import PremiumPopup from '../premium/premiumPopup'


export default function SelectTargetAccount(props) {

    const [targetAccount, setTargetAccount] = useState('')
    const [showTransferError, setShowTrasnsferError] = useState(false)
    const [isTransferLoading, setTransferLoading] = useState('')
    const [triedTracksCount, setTriedTracksCount] = useState(0)
    const [showPremiumOptions, setShowPremiumOptions] = useState(false)


    const navigate = useNavigate();

    const handleCurrentAccountSelectionChange = (event) => {
        setTargetAccount(event.target.value);
        isYoutubeSelected()
    };

    function isYoutubeSelected() {
        if(targetAccount === '') {
            return false;
        }
        for (let i = 0; i < props.connectedAccounts.length; i++) {
            if(props.connectedAccounts[i].id === targetAccount && props.connectedAccounts[i].accountType === "youtube") {
                return true
            }
        }
        return false;
    }
    
    function isAlbumSelected() {

        for (let i = 0; i < props.transferData.length; i++) {
            if(props.transferData[i].sourcePlaylistType === "album") {
                return true
            }
        }
        return false;
    }
    
    function SelectedItems() {
        var playlistCount = 0;
        var albumCount = 0;
        var likedSongsCount = 0;
        for (const transfer of props.transferData) {
            if(transfer.sourcePlaylistType === "playlist") {
                playlistCount++;
            }
            if(transfer.sourcePlaylistType === "liked") {
                likedSongsCount++;
            }
            if(transfer.sourcePlaylistType === "album") {
                albumCount = transfer.sourcePlaylistId.split(',').length
            }
          }
        return(
            <>
                  <Alert severity="info" sx={{m:2}}>Transferring {likedSongsCount>0?" all liked songs,":""} {playlistCount} playlist(s) and {albumCount} album(s)</Alert>
            </>
        )
    }

    function TargetAccountSelector() {
        return (
            <>
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id="demo-simple-select-helper-label">Select Destination Account</InputLabel>
                    <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={targetAccount}
                        label="Select Music Account"
                        onChange={(handleCurrentAccountSelectionChange)}
                        defaultValue={props.selectedAccount}
                    >
                        {/* <MenuItem value="">
                            <em>Apple Music</em>
                        </MenuItem>
                        */}

                        {
                            props.connectedAccounts.map((account) => {
                                if (account.accountType === "spotify") {
                                    if (account.id === props.sourceAccountId) {
                                        return
                                    }
                                    return (<MenuItem value={account.id} key={account.id}>{getIconForTransfer(account.accountType)} &nbsp; {account.profileName}</MenuItem>)
                                }
                            })
                        }

                        {/* <MenuItem value="">
                            <em>Spotify</em>
                        </MenuItem> */}
                        {
                            props.connectedAccounts.map((account) => {
                                if (account.accountType === "appleMusic") {
                                    if (account.id === props.sourceAccountId) {
                                        return
                                    }
                                    return (<MenuItem value={account.id} key={account.id}>{getIconForTransfer(account.accountType)}&nbsp;{account.profileName}</MenuItem>)
                                }
                            })
                        }
                        
                        {
                            props.connectedAccounts.map((account) => {
                                if (account.accountType === "youtube") {
                                    if (account.id === props.sourceAccountId) {
                                        return
                                    }
                                    return (<MenuItem value={account.id} key={account.id}>{getIconForTransfer(account.accountType, account.isYoutubeMusic)}&nbsp;{account.profileName}</MenuItem>)
                                }
                            })
                        }
                    </Select>
                </FormControl>

            </>
        );
    }


    function cancelTransfer() {
        props.setShowTransferTargetMenu(false)
    }

    function startTransfer() {

        setShowTrasnsferError(false)
        setTransferLoading(true)
        let transferData = props.transferData;
        transferData = transferData.map(transfer => ({
            ...transfer,
            "destinationCaId": targetAccount
        }))


        fetch(config.apiUrl + "/transfer", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('user')).accessToken,
            },
            body: JSON.stringify(transferData),
        }).then(response => {
            if (!response.ok) {
                throw new Error('Failed to create transfer job');
            }
            return response.json();
        })
            .then(jsonData => {
                if(jsonData.noOfTransfersRequested != null) {
                    setTransferLoading(false)
                    setTriedTracksCount(jsonData.noOfTransfersRequested)
                    setShowPremiumOptions(true)
                    return
                }
                setTransferLoading(false)
                setShowTrasnsferError(false)
                navigate('/dashboard/transferHistory?message=TRANSFER_STARTED')
            })
            .catch(error => {
                setTransferLoading(false)
                setShowTrasnsferError(true)
            });

    }


    return (
        <>
            {isTransferLoading ? <><CircularProgress /></>

                :
                 <>          
                    <PremiumPopup showPremiumOptions={showPremiumOptions} setShowPremiumOptions={setShowPremiumOptions} triedTracksCount={triedTracksCount}></PremiumPopup>      
                    <TargetAccountSelector></TargetAccountSelector>
                    <>{SelectedItems()}</>
                    {showTransferError ? <><Alert sx={{ m: 2 }} severity="error">Failed to start transfer. Please try again or later.</Alert></> : <></>}

                    {isYoutubeSelected() && isAlbumSelected()? <><Alert severity="error" sx={{ m: 2 }}>Album transfer not supported for youtube.</Alert></> : <></>}

                    <Stack direction="row" spacing={2}>
                        <Button variant="outlined" startIcon={<CancelIcon />} onClick={() => cancelTransfer()}>
                            Cancel
                        </Button>
                        <Button variant="contained" endIcon={<SendIcon />} onClick={() => startTransfer()} disabled={targetAccount==='' }>
                            {props.transferData[0].transferMethod === "automatic" ? "Transfer" : "Load Songs"}
                        </Button>
                    </Stack>
                </>}


            <></>

        </>
    )
}
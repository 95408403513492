import React, { useState } from 'react';
import { Button, TextField, InputAdornment, IconButton, Typography, Box } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { Alert } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';

import config from '../../../config';

import {getCaTypetoName, getIconForTransferUI, getThumbnailUrlCleaned } from '../../../utils'

function SearchBoxWithAppleMusicIcon(props) {
  const [searchQuery, setSearchQuery] = useState('');
  const [loadedSongs, setLoadedSongs] = useState([])

  const [isSearchLoading, setSearchLoading] = useState(false)
  const [errorLoading, setErrorLoading] = useState('')

//   let loadedSongs = [{
//     "id": "1400761381",
//     "title": "Energy (Stay Far Away)",
//     "thumbnailUrl": "https://is1-ssl.mzstatic.com/image/thumb/Music124/v4/a3/81/bf/a381bfbd-fd5d-6649-c215-b4882a65ff49/192562702512.jpg/{w}x{h}bb.jpg",
//     "artist": "Skepta & Wizkid"
// },
// {
//   "id": "1400761381",
//   "title": "Energy (Stay Far Away)",
//   "thumbnailUrl": "https://is1-ssl.mzstatic.com/image/thumb/Music124/v4/a3/81/bf/a381bfbd-fd5d-6649-c215-b4882a65ff49/192562702512.jpg/{w}x{h}bb.jpg",
//   "artist": "Skepta & Wizkid"
// }]

  const handleSearchClick = () => {
    const apiUrl = config.apiUrl + "/connectedAccount/"+props.accountId+"/searchSongs?key="+searchQuery
    setSearchLoading(true)
    fetch(apiUrl,{
      headers: {
          'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('user')).accessToken,
          'Content-Type': 'application/json'
        }
      }
  )
  .then(response => {
        if (!response.ok) {
          setErrorLoading("Failed to search songs. Please try again or contact us.")
          throw new Error()
        }
        setSearchLoading(false)
        return response.json();
  })
  .then(data => {
      setErrorLoading('')
      setLoadedSongs(data)
      setSearchLoading(false)
  })
  .catch(error => {
      setSearchLoading(false)
      setErrorLoading("Failed to search songs. Please try again or contact us.")
  });
  };

  return (
    <Box sx={{ width: '300px', margin: '0 auto' }}>
      <TextField
        label="Search"
        variant="outlined"
        fullWidth
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleSearchClick} edge="end" aria-label="search">
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
       {getIconForTransferUI(props.accountType, props.isYTMusic)}
        <Typography variant="body2">Searching {getCaTypetoName(props.accountType, props.isYTMusic)}</Typography>
      </Box>

      {  errorLoading === "" ? <></> : <Alert  severity="error" icon={false} sx={{ m: 1 }}  >{errorLoading}</Alert>}
                    
      {isSearchLoading? <CircularProgress/> : 
        loadedSongs.map((song) => (
          <Box id={song.id} sx={{  alignItems: 'center', mt: 2 }} onClick={() => props.handleSearchModalCloseWithSelection(props.sourceSongId, song)}>
          <div style={{ display: 'flex', alignItems: 'center' }}  >
          {getThumbnailUrlCleaned(song.thumbnailUrl, props.accountType) === "" ? getIconForTransferUI(props.accountType, props.isYTMusic) : <img src={ getThumbnailUrlCleaned(song.thumbnailUrl, props.accountType) } alt={song.title} style={{ width: 50, height: 50, marginRight: 8 }} />}
              <div>
                  <div>{song.title}</div>
                  <div style={{ fontSize: '0.875em', color: 'gray' }}>{song.artist}</div>
              </div>
          </div>
          <Divider sx={{my: 2}}/>
          </Box>
            ))
      
      }



            <Divider sx={{mt: "4rem"}}/>
            
                <Button onClick={props.handleSearchModalClose} variant="outlined" color={"error"} >Cancel</Button>

    </Box>
  );
}

export default SearchBoxWithAppleMusicIcon;

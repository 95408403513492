import React, { useState, useEffect,  } from 'react';
import { Alert, Container, Paper, Typography, Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Box } from '@mui/material';

import CircularProgress from '@mui/material/CircularProgress';
import LoadingButton from '@mui/lab/LoadingButton';

import config  from '../config';  

const Profile = () => {
  const [loading, setLoading] = useState(true); // State to track loading state
  const [isNameChanging, setNameChanging] = useState(false); // State to track loading state
  const [isPasswordChanging, setPasswordChanging] = useState(false); // State to track loading state
  
    
  const [name, setName] = useState('');
  const [email, setEmail] = useState(''); // Email is usually not changed
  const [tier, setTier] = useState(''); // Tier can be 'Free' or 'Premium'

  const [openNameDialog, setOpenNameDialog] = useState(false);
  const [openPasswordDialog, setOpenPasswordDialog] = useState(false);

  const [newName, setNewName] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errResponse, setErrResponse] = useState('')
  const [passwordFormErrors, setPasswordFormErrors] = useState({})



  useEffect(() => {
      const fetchData = async () => {
          try {
              setLoading(true); // Set loading state to true
              const response = await fetch(config.apiUrl + "/user", {
                  headers: {
                      'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('user')).accessToken,
                  }
              });

              const status = await response.status;
              if (status === 200) {
                  let data = await response.json()
                  setName(data.firstName)
                  setEmail(data.email)
                  setTier(data.premium ? 'Premium' : 'Free')
              } else {
                  throw new Error('Failed to get profile');
              }

          } catch (error) {
              setErrResponse(error.message)
              console.error('Error fetching data:', error);
              setLoading(false)
          } finally {
              setLoading(false); // Set loading state to false after fetch completes
              
          }
      };

      fetchData();
  }, []);

  

  const handleNameUpdate = () => {
      setNameChanging(true)
      fetch(config.apiUrl + "/user/changeName", { // Replace with your actual endpoint
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('user')).accessToken,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({"id":JSON.parse(localStorage.getItem("user")).id, "newName":newName})
    })
      .then(response => {
          if (!response.ok) {
              if (response.status === 400) {
                  return response.text().then(text => {
                      throw new Error(text || 'Something went wrong');
                  });
              }
              throw new Error('Sorry, some error occured. Please try again later or let us know');
          }
          return response.text();
      })
      .then(data => {
          window.location.reload();
      })
      .catch((error) => {

          setOpenNameDialog(false);
          setNameChanging(false)
          setErrResponse(error.message)
          console.error('Error:', error);
      });
  };

  const validatePasswordFormErrors = () => {
    let hasErr = false

    if ('' === oldPassword) {
      passwordFormErrors.oldPassword = "Password is required";
      hasErr = true
    } else {
      passwordFormErrors.oldPassword = null
    }
    if (newPassword.length < 6) {
      hasErr = true
      passwordFormErrors.newPassword = "New password is must have 6 letter";
    } else {
      passwordFormErrors.newPassword = null
    }
    if (newPassword !== confirmPassword) {
      hasErr = true
      passwordFormErrors.confirmPassword = "Passwords do not match";
    } else {
      passwordFormErrors.confirmPassword = null
    }
    return hasErr
};


  const handlePasswordChange = () => {
    setPasswordFormErrors({})
    if(validatePasswordFormErrors()){
      setPasswordFormErrors({...passwordFormErrors})
      return
    }



    setPasswordChanging(true)
    fetch(config.apiUrl + "/user/changePassword", { // Replace with your actual endpoint
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('user')).accessToken,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({"id":JSON.parse(localStorage.getItem("user")).id, "oldPassword":oldPassword,"newPassword":newPassword,"confirmPassword":confirmPassword})
  })
    .then(response => {
        if (!response.ok) {
            if (response.status === 400) {
                return response.text().then(text => {
                    throw new Error(text || 'Something went wrong');
                });
            }
            throw new Error('Sorry, some error occured. Please try again later or let us know');
        }
        return response.text();
    })
    .then(data => {
        window.location.reload();
    })
    .catch((error) => {

        setOpenPasswordDialog(false);
        setPasswordChanging(false)
        setErrResponse(error.message)
    });
  };


  function NameDialog() {
    return (
      <Dialog open={openNameDialog} onClose={() => setOpenNameDialog(false)}>
      <DialogTitle>Update Name</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Enter your new name below.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          label="New Name"
          type="text"
          fullWidth
          value={newName}
          onChange={(e) => setNewName(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenNameDialog(false)} color="primary">Cancel</Button>
        {isNameChanging?<LoadingButton loading variant="outlined">Update</LoadingButton> : <Button onClick={handleNameUpdate} color="primary">Update</Button>}
      </DialogActions>
    </Dialog>
    )
  }

  function PasswordDialog() {
    return (
      <Dialog open={openPasswordDialog} onClose={() => setOpenPasswordDialog(false)}>
      <DialogTitle>Change Password</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Enter your old password and new password below.
        <TextField
          margin="dense"
          label="Old Password"
          type="password"
          fullWidth
          value={oldPassword}
          onChange={(e) => setOldPassword(e.target.value)}
        />
        {passwordFormErrors.oldPassword && <Alert  severity="error" icon={false} >{passwordFormErrors.oldPassword}</Alert>}
        <TextField
          margin="dense"
          label="New Password"
          type="password"
          fullWidth
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
        {passwordFormErrors.newPassword && <Alert  severity="error" icon={false} >{passwordFormErrors.newPassword}</Alert>}
      <TextField
          margin="dense"
          label="Confirm New Password"
          type="password"
          fullWidth
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
      {passwordFormErrors.confirmPassword && <Alert  severity="error" icon={false} >{passwordFormErrors.confirmPassword}</Alert>}
     
      </DialogContentText> 
      
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenPasswordDialog(false)} color="primary">Cancel</Button>
        {isPasswordChanging?<LoadingButton loading variant="outlined">Change Password</LoadingButton> : <Button onClick={()=>handlePasswordChange()} color="primary">Change Password</Button>}
      </DialogActions>
    </Dialog>
    )
  }


  function ProfileView() {
    return (
      <Container maxWidth="sm">
          <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
  
    {errResponse && <Alert  severity="error" icon={false} >{errResponse}</Alert>}
  
      <Typography variant="h4" gutterBottom>Your Pofile</Typography>
      <Typography variant="body1"><strong>Name:</strong> {name}</Typography>
      <Typography variant="body1"><strong>Email:</strong> {email}</Typography>
      <Typography variant="body1"><strong>Tier:</strong> {tier}</Typography>
      <Box mt={2}>
        <Button variant="contained" color="primary" onClick={() => setOpenNameDialog(true)} style={{ marginRight: '10px' }}>
          Update Name
        </Button>
        <Button variant="contained" color="secondary" onClick={() => setOpenPasswordDialog(true)}>
          Change Password
        </Button>
      </Box>
    </Paper>
  
    {NameDialog()}
    

  
    {PasswordDialog()}

      </Container>
    );
  }


  return (
    <>
    {loading ? <CircularProgress />
        : <>{ProfileView()}</>
    }
    </>
  )

};

export default Profile;

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import config from '../../../config';
import {
  CircularProgress,
  Alert,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Grid,
  Paper,
  Typography,
  Button,
  TextField,
  Box
} from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { getIconForTransfer, getThumbnailUrlCleaned,getIconForTransferUI } from '../../../utils';
import Icon from '@mui/material/Icon';
import Divider from '@mui/material/Divider';

export default function EditTransfer() {
  const { transferId } = useParams();

  const [transfer, setTransfer] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showGetTransferError, setShowGetTransferError] = useState("");
  const [selectedMissingItem, setSelectedMissingItem] = useState("");
  
  const [searchQuery, setSearchQuery] = useState('');
  const [loadedSongs, setLoadedSongs] = useState([])

  const [isSearchLoading, setSearchLoading] = useState(false)
  const [errorLoading, setErrorLoading] = useState('')
  const [showNoResults, setShowNoResults] = useState(false)

  const [isAddingSong, setIsAddingSong] = useState(false)
  const [selectedSongId, setSelectedSongId] = useState("")

  const [showAddSongError, setShowAddSongError] = useState('')
  const [showAddComplete, setShowAddComplete] = useState(false)
  useEffect(() => {
    setLoading(true);

    fetch(`${config.apiUrl}/transfer/getSelectedTransfer?selected=${transferId}`, {
      headers: {
        'Authorization': `Bearer ${JSON.parse(localStorage.getItem('user')).accessToken}`,
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data.code === "ACCOUNT_DISCONNECTED") {
          setShowGetTransferError(data.message);
          return;
        }
        setTransfer(data);
      })
      .catch(error => {
        console.error("Error fetching transfer:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [transferId]);

  const handleSelectMissingItem = (event) => {
    setSelectedMissingItem(event.target.value);
    setSearchQuery(event.target.value);
  };

  const getMissingListViewitem = (missingLog) => {
    return (
      <FormControl sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id="demo-simple-select-helper-label">Select a missing item</InputLabel>
        <Select
          defaultValue=""
          label="Select a missing item"
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          onChange={handleSelectMissingItem}
          value={selectedMissingItem}
        >
          {missingLog.filter(log => log !== "").map(log => (

              <MenuItem key={log} value={log}>
                {getIconForTransfer(transfer.destinationAccountType, transfer.isDestYTM)}
                &nbsp;
                {log}
              </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };


  const handleSearchClick = () => {
    setSearchLoading(true)
    setShowAddSongError("")
    setShowAddComplete(false)
    const apiUrl = config.apiUrl + "/connectedAccount/"+transfer.destCaId+"/searchSongs?isAlbum="+(transfer.sourcePlayListType==="album")+"&key="+searchQuery
    fetch(apiUrl,{
      headers: {
          'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('user')).accessToken,
          'Content-Type': 'application/json'
        }
      }
  )
  .then(response => {
        if (!response.ok) {
          setErrorLoading("Failed to search songs. Please try again or contact us.")
          throw new Error()
        }
        setSearchLoading(false)
        return response.json();
  })
  .then(data => {
    {console.log(data)}
    if(data.length === 0) {
      setShowNoResults(true)
    } else {
      setShowNoResults(false)
    }
      setErrorLoading('')
      setLoadedSongs(data)
      setSearchLoading(false)
  })
  .catch(error => {
      setSearchLoading(false)
      setErrorLoading("Failed to search songs. Please try again or contact us.")
  });
  };


  const getSearchBoxMessage = () => {
    if(transfer.sourcePlayListType === "playlist") {
        return "Searching for tracks: " 
    }
    if(transfer.sourcePlayListType === "liked") {
        return "Searching for tracks: " 
    }
    if(transfer.sourcePlayListType === "album") {
        return "Searching for album: " 
    }
    
}

const handleAddSong = (song) => {
    setSelectedSongId(song.id)
    setIsAddingSong(true)
    setShowAddSongError("")
    setShowAddComplete(false)
    fetch(`${config.apiUrl}/transfer/replace`, {
        headers: {
            'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('user')).accessToken,
            'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify({
            transferId: transferId,
            idToAdd: song.id,
            stringToRemove: selectedMissingItem
        })
    })
    .then(response => {
        if (!response.ok) {
            setIsAddingSong(false)  
            setShowAddSongError("Failed to add song. Please try again or contact us.")
        }
        return response
    })
    .then(data => {
        setIsAddingSong(false)
        setSelectedSongId("")
        setShowAddSongError("")
        setShowAddComplete(true)
        setLoadedSongs([])

        const newTransfer = {...transfer}
        newTransfer.missedLog = newTransfer.missedLog.replace(selectedMissingItem, "")
        setTransfer(newTransfer)
        setSelectedMissingItem("")

        if(newTransfer.missedLog.replace(/\s+/g, '') === "") {
            window.location.href = config.transferHistoryPath + "?message=MISSING_REPLACED"
        }
    })
    .catch(error => {
        setIsAddingSong(false)
        setShowAddSongError("Failed to add song. Please try again or contact us.")
    })
}

function getAddButtonText () {
    if(transfer.sourcePlayListType === "playlist") {
        return "Add to Playlist"
    }
    if(transfer.sourcePlayListType === "liked") {
        return "Add to Liked"
    }
    if(transfer.sourcePlayListType === "album") {
        return "Add to Albums"
    }
    return "Add"
}




function getTransferTypeDisplay() {
  return (
    <Box
    mx={2}
    >
      {getIconForTransfer(transfer.sourceAccountType, transfer.isSourceYTM)}<span> to </span>{getIconForTransfer(transfer.destinationAccountType, transfer.isDestYTM)}

      <Typography>
      <strong>
        {transfer.sourcePlayListType === "liked"
          ? "Liked/Saved Songs Transfer"
          : transfer.sourcePlayListType === "album"
          ? " Albums transfer"
          : " " + transfer.playlistName}
      </strong>
    </Typography>
      </Box>
  )
}

const getTranferSourceDestinationIcon = () => {
return (
  <Typography>
        <Icon fontSize="small">
          {getIconForTransfer(transfer.sourceAccountType, transfer.isSourceYTM)}
        </Icon>
        <span>  &nbsp; to  &nbsp; </span>
        <Icon fontSize="small">
          {getIconForTransfer(transfer.destinationAccountType, transfer.isDestYTM)}
        </Icon>
  </Typography>
)
}


  return (
    <Grid item xs={12}>
      <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
        <Typography component="h2" variant="h6" color="primary" gutterBottom>
          Edit Transfer
        </Typography>



        {showGetTransferError !== "" && <Alert severity="error">{showGetTransferError}</Alert>}
        
        {loading ? (
          <CircularProgress />
        ) : (
          
          transfer && (
            <>
                  {getTransferTypeDisplay()}
              {getMissingListViewitem(transfer.missedLog.split("\n"))}
            </>
          )
        )}

        {selectedMissingItem !== "" ? <>
            <Grid container spacing={2} alignItems="center" sx={{ width: '80%', margin: '20px auto 0' }}>
                <Grid item xs={12}>
                    <TextField
                    fullWidth
                    label={getSearchBoxMessage()}
                    variant="outlined"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                            {getIconForTransfer(transfer.destinationAccountType, transfer.isDestYTM)}
                            </InputAdornment>
                        ),
                        }}
                    >
                    </TextField>
                </Grid>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
                    <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSearchClick}
                    size="large"
                    sx={{ padding: '10px 40px', fontSize: '1.2rem' }}
                    >
                    Search
                    </Button>
                </Grid>
                </Grid>
                </> : <></>}
        
        {showAddSongError !== "" && <Alert sx={{mt: 2}} severity="error">{showAddSongError}</Alert>}
        {showAddComplete && <Alert sx={{mt: 2}} severity="success">Song added to playlist/album</Alert>}

        {isSearchLoading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            <CircularProgress />
          </Box>
        ) : (
          loadedSongs.length > 0  ? (
            <Box sx={{ mt: 4 }}>
              {
                      loadedSongs.map((song) => (
                        
                        <Box id={song.id} sx={{  alignItems: 'center', mt: 2 }}>
                             <div style={{ display: 'flex', alignItems: 'center' }}  >
                              
                              {getThumbnailUrlCleaned(song.thumbnailUrl, transfer.destinationAccountType) === "" ? getIconForTransferUI(transfer.destinationAccountType, transfer.isDestYTM) : <img src={ getThumbnailUrlCleaned(song.thumbnailUrl, transfer.destinationAccountType) } alt={song.title} style={{ width: 80, height: 80, marginRight: 12 }} />}
                                  <div>
                                      <div>{song.title}</div>
                                      <div style={{ fontSize: '0.875em', color: 'gray' }}>{song.artist}</div>
                                      <Button variant="outlined" color="primary" onClick={() => handleAddSong(song)} disabled={isAddingSong}> {getAddButtonText()} {isAddingSong && song.id === selectedSongId ? <CircularProgress size={20} /> : ""}</Button>
                                  </div>
                              </div>
                              <Divider sx={{my: 2}}/>
                        </Box>
                          ))
                }
            </Box>
          ) : (
            showNoResults ? <Alert severity="info" sx={{ mt: 4 }}>No results found</Alert> : <></>
          )
        )}


      </Paper>
    </Grid>
  );
}

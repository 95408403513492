import React, { useState, useEffect } from 'react';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import AccountsDetails from './accountsDetails';
import Library from './library'

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Collapse from '@mui/material/Collapse';

import SelectTargetAccount from './selectTargetAccount'
import CircularProgress from '@mui/material/CircularProgress';

import { Alert } from '@mui/material';
import AddNewAccount from './addNewAccount'

import config from '../../config';

export default function ConnectedAccounts() {

    
    const [showTransferTargetMenu, setShowTransferTargetMenu] = useState(false)

    const [transferData, setTransferData] = useState([])

    const [connectedAccounts, setConnectedAccounts] = useState(null)
    const [loading, setLoading] = useState(true); // State to track loading state


    const params = new URLSearchParams(window.location.search);
    const [redirectMessage, setRedirectMessage] = useState(params.get('message'))
    const [addedAccount, setAddedAccount] = useState(params.get('addedAccount'))
    const [selectedAccount, setSelectedAccount] = useState(addedAccount === null ? "" : addedAccount)

    const [alertOpen, setAlertOpen] = useState(redirectMessage != null);

    ///Get accounts
    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true); // Set loading state to true
                const user = JSON.parse(localStorage.getItem('user'));
                const response = await fetch(config.apiUrl + "/connectedAccount/" + user.id + "/list", {
                    headers: {
                        'Authorization': 'Bearer ' + user.accessToken,
                    }
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }
                const data = await response.json();
                setConnectedAccounts(data)


            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false); // Set loading state to false after fetch completes
            }
        };

        fetchData(); // Call fetchData function when component mounts
    }, []); // Empty dependency array ensures useEffect runs only once

    function getAlertMessage() {
        switch(redirectMessage) {
            case "NO_YOUTUBE_CHANNEL":
                return "Please create a channel in your Youtube account to transfer playlists."
            case "YOUTUBE_ADD_ERROR":
                return "Error in connecting youtube account. Please try again or contact us."
            case "SPOTIFY_ADD_ERROR":
                return "Error in connecting Spotify account. Please try again or contact us."
            case "APPLE_ADD_ERROR":
                return "Error in connecting Apple Music account. Please try again or contact us."
            case "TIDAL_ADD_ERROR":
                return "Error in connecting Tidal account. Please try again or contact us."
            case "FAILED_TO_DISCONNECT":
                return "Failed to disconnect. Please try again or contact us."
            case "YOUTUBE_ADDED":
                return "Connected YouTube account."
            case "SPOTIFY_ADDED":
                return "Connected Spotify account."
            case "APPLE_ADDED":
                return "Connected Apple Music account."
            case "TIDAL_ADDED":
                return "Connected Tidal account."

            default:
                return "";
          }
    }
    function getAlertType() {
        switch(redirectMessage) {
            case "NO_YOUTUBE_CHANNEL":
                return "error"
            case "YOUTUBE_ADD_ERROR":
                return "error"
            case "SPOTIFY_ADD_ERROR":
                return "error"
            case "APPLE_ADD_ERROR":
                return "error"
            case "TIDAL_ADD_ERROR":
                return "error"
            case "FAILED_TO_DISCONNECT":
                return "error"
            case "YOUTUBE_ADDED":
                return "success"
            case "SPOTIFY_ADDED":
                return "success"
            case "APPLE_ADDED":
                return "success"
            case "TIDAL_ADDED":
                return "success"

            default:
                return "";
          }
    }


    return (
        <>
        
            <Grid item xs={12}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                    {showTransferTargetMenu ? <><SelectTargetAccount connectedAccounts={connectedAccounts} transferData={transferData} sourceAccountId={selectedAccount} setShowTransferTargetMenu={setShowTransferTargetMenu}></SelectTargetAccount></>:
                        <>
                        
                            <Typography component="h2" variant="h6" color="primary" gutterBottom>
                                Your Library
                                <AddNewAccount></AddNewAccount>
                            </Typography>


                            {redirectMessage === "" ?<></>:
                                <Collapse in={alertOpen}>
                                        <Alert
                                        action={
                                            <IconButton
                                            aria-label="close"
                                            color="inherit"
                                            size="small"
                                            onClick={() => {
                                                setAlertOpen(false);
                                            }}
                                            >
                                            <CloseIcon fontSize="inherit" />
                                            </IconButton>
                                        }
                                        sx={{ mt: 2, mb: 2 }} 
                                        severity={getAlertType()}
                                        icon={false} 
                                        >
                                        {getAlertMessage()}
                                        </Alert>
                                    </Collapse> }
                        
                            {loading ? <CircularProgress/> : <>
                                        <AccountsDetails selectedAccount={selectedAccount} onSelectedAccountChange={setSelectedAccount} connectedAccounts={connectedAccounts} defaultSelectText="Select Music Account"></AccountsDetails>
                                    
                                    {selectedAccount === '' ?
                                        <Typography variant="caption" display="block" gutterBottom></Typography>
                                        //key={selectedAccount} to reset state say if things fail it still retains old account state
                                        : <Library key={selectedAccount} selectedAccount={selectedAccount} setShowTransferTargetMenu={setShowTransferTargetMenu} setTransferData={setTransferData} connectedAccounts={connectedAccounts}/>
                                    }

                                    </> }

                            
                        </>
                    }

                    

                </Paper>
            </Grid>

        </>
    )

}


import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { Menu, MenuItem, useMediaQuery, IconButton } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import config from '../../config';

import Box from '@mui/material/Box'
import {getIconForTransfer} from './../../utils'
import  TidalIcon  from './../../assets/tidal_logo_black.png';
import { Alert } from '@mui/material';

export default function AddNewAccount() {
    const navigate = useNavigate();
    const theme = useTheme();

    const [musicKit, setMusicKit] = useState(null);

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));


    const [anchorEl, setAnchorEl] = useState(null);
    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };


    //Load apple music Lib
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://js-cdn.music.apple.com/musickit/v1/musickit.js';
        script.async = true;
        script.onload = () => {
            setMusicKit(window.MusicKit.configure({
                developerToken: config.appleDeveloperToken,
                app: {
                    name: 'Music Porter',
                    build: '1.0.0'
                }
            }));
        };
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, []);


    const handleAppleConnect = (event) => {
        try{
            if (musicKit) {
                // Example: Play a song from the Apple Music catalog
                musicKit.authorize().then((token) => {

                    const apiUrl = `${config.apiUrl}/appleMusic/auth/callback/internal?token=${encodeURIComponent(token)}`;

                    const user = JSON.parse(localStorage.getItem('user'));
                    fetch(apiUrl, {
                            headers: {
                                'Authorization': 'Bearer ' + user.accessToken,
                            }
                        })
                      .then(response => {
                            if (!response.ok) {
                                navigate(config.accountsPath+'?message=APPLE_ADD_ERROR')
                            }
                            return response.json();
                      })
                      .then(data => {
                        window.location.href = (config.accountsPath+'?message=APPLE_ADDED&addedAccount='+data.id)
                        
                    })
                      .catch(error => {
                    });

                });            
            } else {
                console.log("failed to connect music kit")
            }
        } catch (error) {
            console.error('Error connecting Apple music:', error);
        } finally {
            
        }
    };



    const menuItems = [
        { label: 'ADD', onClick: () => navigate("/connect/spotify"), icon: getIconForTransfer("spotify") },
        { label: 'ADD', onClick: handleAppleConnect, icon: getIconForTransfer("appleMusic") },
        // { label: 'ADD', onClick: () => navigate("/connect/tidal"), icon: getIconForTransfer("tidal") },
        { label: 'ADD', onClick: () => navigate("/connect/youtube"), icon: getIconForTransfer("youtube") },
        { label: 'ADD', onClick: () => navigate("/connect/youtubeMusic"), icon: getIconForTransfer("youtube", true) },
        // Add more items as needed
    ];
    return (
        <Box>
            {/* <Box display="flex" justifyContent="flex-end" p={2}>
                <Stack spacing={1} direction="row">
                    <Button variant="outlined" onClick={() => navigate("/connect/spotify")}>{getIconForTransfer("spotify")}&nbsp;Add</Button>
                    <Button variant="outlined" onClick={handleAppleConnect}>{getIconForTransfer("appleMusic")}&nbsp;Add </Button>
                    <Button variant="outlined" onClick={() => navigate("/connect/youtube")}>{getIconForTransfer("youtube")}&nbsp;Add </Button>
                    <Button variant="outlined" onClick={() => navigate("/connect/youtubeMusic")}>{getIconForTransfer("youtube", true)}&nbsp;Add </Button>
                </Stack>
            </Box> */}
                    <Box display="flex" justifyContent="flex-end" p={2}>
            {isMobile ? (
                <>
                    <Button variant="outlined" onClick={handleMenuOpen}>
                        Add Account
                    </Button>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                    >
                        {menuItems.map((item, index) => (
                            <MenuItem key={index} onClick={() => { handleMenuClose(); item.onClick(); }}>
                                {item.icon}&nbsp;{item.label}
                            </MenuItem>
                        ))}
                    </Menu>
                </>
            ) : (
                <Stack spacing={1} direction="row">
                    {menuItems.map((item, index) => (
                        <Button key={index} variant="outlined" onClick={item.onClick}>
                            {item.icon}&nbsp;{item.label}
                        </Button>
                    ))}
                </Stack>
            )}
        </Box>
            <Box>
                {/* <Alert severity="error"></Alert> */}
            </Box>
        </Box>
    );
}
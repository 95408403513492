import { useEffect, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Image from 'material-ui-image'
import { useNavigate, redirect,useParams } from 'react-router-dom';
import { Alert } from '@mui/material';

import config from '../config';

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://musicporter.com/">
                Music Porter
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function SignIn() {

    const navigate = useNavigate();
    const [errors, setErrors] = useState({});
    const [errResponse, setErrResponse] = useState("");

    const { token, userId } = useParams();

    const [formData, setFormData] = useState({
        passwordResetToken: token,
        id: userId,
        password: '',
        confirmPassword: ''
    });


    const validate = (submittedFormData) => {
        let errors = {};

        if (!submittedFormData.password) {
            errors.password = "Password is required";
        } else if (submittedFormData.password.length < 6) {
            errors.password = "Password must be at least 6 characters";
        }

        if (!submittedFormData.confirmPassword) {
            errors.confirmPassword = "Confirm password is required";
        } else if (submittedFormData.confirmPassword !== submittedFormData.password) {
            errors.confirmPassword = "Passwords do not match";
        }

        return errors;
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        var formDataObj = new FormData(e.currentTarget);
        var newData = {
            passwordResetToken: token,
            id: userId,
            password: formDataObj.get('password'),
            confirmPassword: formDataObj.get('confirmPassword')
        }
        setFormData(newData)

        const validationErrors = validate(newData);
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            setErrors({});
            fetch(config.apiUrl + "/user/updatePassword", { // Replace with your actual endpoint
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(newData)
            })
                .then(response => {
                    if (!response.ok) {
                        if (response.status === 400) {
                            return response.text().then(text => {
                                throw new Error(text || 'Something went wrong');
                            });
                        }
                        throw new Error('Sorry, some error occured. Please try again later or let us know');
                    }
                    return response.text();
                })
                .then(data => {
                    setErrResponse("Your password is updatted, please login to continue.");
                    navigate(config.loginPath + "?message=PASSWORD_UPDATED")
                })
                .catch((error) => {
                    setErrResponse(error.message)
                    console.error('Error:', error);
                });
        }
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Box
                    component="img"
                    sx={{
                        width: 350
                    }}
                    alt="Music Porter logo."
                    src="https://musicporter.com/wp-content/uploads/2024/05/music-porter-high-resolution-logo-transparent-1.png?auto=format&w=350&dpr=2"
                    />
                    

                    <Typography component="h1" variant="h5" mt={5}>
                        Enter New Password
                    </Typography>

                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>

                        <TextField
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="new-password"
                            margin="normal"
                        />
                        {errors.password && <Alert  severity="error" icon={false} >{errors.password}</Alert>}


                        <TextField
                            required
                            fullWidth
                            name="confirmPassword"
                            label="Confirm password"
                            type="password"
                            id="confirmPassword"
                            autoComplete="confirmPassword"
                            margin="normal"
                        />
                        {errors.confirmPassword && <Alert severity="error" icon={false} >{errors.confirmPassword}</Alert>}


                    {errResponse && <Alert severity="error" icon={false} >{errResponse}</Alert>}
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Reset Password
                    </Button>
                    
                    </Box>

                    <Grid container>
                        <Grid item xs>
                            <Link href="/login" variant="body2">
                                Back to Login
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link href="/signup" variant="body2">
                                {"Don't have an account? Sign Up"}
                            </Link>
                        </Grid>
                    </Grid>
                </Box>

                <Copyright sx={{ mt: 8, mb: 4 }} />
            </Container>
        </ThemeProvider>
    );
}
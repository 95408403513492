
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import RefreshIcon from '@mui/icons-material/Refresh';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';

import { Alert } from '@mui/material';
import Grid from '@mui/material/Grid';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Paper} from '@mui/material';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CancelIcon from '@mui/icons-material/Cancel';
import SendIcon from '@mui/icons-material/Send';
import { Typography } from '@mui/material';
import Stack from '@mui/material/Stack';


import React, { useState, useEffect } from 'react';

import config from '../../../config';


import SearchMTSongModal from './searchMtSongModal'

import { useParams } from 'react-router-dom';


import {getCaTypetoName, getIconForTransferUI, getThumbnailUrlCleaned } from '../../../utils'


export default function CompleteManualTransfer() {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [loading, setLoading] = useState(true); // State to track loading state
  const { transferId } = useParams();
  const [songsIdToIngore, setSongsIdToIgnore] = useState(new Set([]))


  const [loadedSongs, setLoadedSongs] = useState('')
  const [errorLoading, setErrorLoading] = useState('')
  const [errorSaving, setErrorSaving] = useState('')
  const [isStartingTransfer, setStartingTransfer] = useState(false)

  
  const [searchModalOpen, setSearchModalOpen] = React.useState(false);
  const [currentlySearchingSong, setCurrentlySearchingsong] = useState("")
  const handleSearchModalOpen = (sourceSongId) => {setCurrentlySearchingsong(sourceSongId);setSearchModalOpen(true)};
  const handleSearchModalClose = () => {setSearchModalOpen(false); setCurrentlySearchingsong("")};




  const modalStyle = {

    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxHeight: '80vh', // Set a maximum height for the modal
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    width: 400,
    overflowY: 'auto', // Enable vertical scrolling
  };


  function handleSearchModalCloseWithSelection (sourceSongId, destSong) {
    var bkp = loadedSongs
    for(var i  in bkp.matches) {
        if(bkp.matches[i].source.id === sourceSongId) {
            bkp.matches[i].target = destSong;
        }
    }
    setLoadedSongs(bkp)
    handleSearchModalClose()
}

function onIgnore(song, remove) {
    const newSet = new Set(songsIdToIngore)
    if(remove) {
        newSet.delete(song.id)
    } else {
        newSet.add(song.id)
    }
    setSongsIdToIgnore(newSet)
};

function onEdit(song) {
    handleSearchModalOpen(song.id)
};

function isIgnored(id) {
    for(let idIgnore of songsIdToIngore) {
        if(id === idIgnore) {
            return true
        }
    }
    return false
}



  useEffect(() => {
    
    setLoading(true)
    const fetchData = async () => {

        fetch(config.apiUrl + "/transfer/history/saved?selected="+transferId,{
            headers: {
                'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('user')).accessToken,
                'Content-Type': 'application/json'
              }
            }
        )
        .then(response => {
              if (!response.ok) {
                throw new Error('Failed to get saved');
              }
              return response.json();
        })
        .then(data => {
            setLoadedSongs(data)
            setErrorLoading('')
            setLoading(false)
        })
        .catch(error => {
            console.error('Error fetching data:', error);
            setErrorLoading("Failed to load saved songs. Please try again or contact us.")
            setLoading(false)
      }); 
    };

    fetchData();
  }, []);



  function startTransfer() {

    const apiUrl = config.apiUrl + "/transfer/manual"
    let newData = loadedSongs
    newData.songsIdToExclude = Array.from(songsIdToIngore)
    newData.currentPage = 0
    newData.nextPageToken = ""
    newData.hasMore = false
    setStartingTransfer(true)
    fetch(apiUrl,{
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('user')).accessToken,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(newData)
        }
    )
    .then(response => {
          if (!response.ok) {
            setStartingTransfer(false)
            setErrorSaving("Failed to save songs. Please try again or contact us.")
            throw new Error()
          }
          return response;
    })
    .then(data => {
        setErrorSaving('')
        setStartingTransfer(false)
        window.location.href=config.transferHistoryPath+"??message=TRANSFER_COMPLETED"
    })
    .catch(error => {
        setStartingTransfer(false)
        setErrorSaving("Failed to save songs. Please try again or contact us.")
  });
}

function mTTable() {
return(
    <Grid item xs={12}>
    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
        <TableContainer component={Paper}>
            <Table aria-label="songs comparison table">
                <TableHead>
                    <TableRow>
                        <TableCell>{getCaTypetoName(loadedSongs.sourceAccountType, loadedSongs.isSourceYTMusic)}</TableCell>
                        <TableCell>{getCaTypetoName(loadedSongs.destAccountType, loadedSongs.isDestYTMusic)}</TableCell>
                        <TableCell align="right">Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loadedSongs.matches.map((song) => (
                        <TableRow key={song.source.id} sx={isIgnored(song.source.id)?{  backgroundColor: '#f5f5f5' }:{}}>
                            <TableCell>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                {getThumbnailUrlCleaned(song.source.thumbnailUrl, loadedSongs.sourceAccountType) === "" ? getIconForTransferUI(loadedSongs.sourceAccountType, loadedSongs.isSourceYTMusic) : <img src={ getThumbnailUrlCleaned(song.source.thumbnailUrl, loadedSongs.sourceAccountType) } alt={song.source.title} style={{ width: 50, height: 50, marginRight: 8 }} />}
                                    <div>
                                        <div>{song.source.title}</div>
                                        <div style={{ fontSize: '0.875em', color: 'gray' }}>{song.source.artist}</div>
                                    </div>
                                </div>
                            </TableCell>
                            <TableCell>
                                {song.target == null ? <><Typography sx={{ color: 'red' }}>No matching song! Try searching here</Typography></> 
                                :
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    {getThumbnailUrlCleaned(song.target.thumbnailUrl, loadedSongs.destAccountType) === "" ? getIconForTransferUI(loadedSongs.destAccountType, loadedSongs.isDestYTMusic) : <img src={ getThumbnailUrlCleaned(song.target.thumbnailUrl, loadedSongs.destAccountType) } alt={song.target.title} style={{ width: 50, height: 50, marginRight: 8 }} />}
                                    <div>
                                        <div>{song.target.title}</div>
                                        <div style={{ fontSize: '0.875em', color: 'gray' }}>{song.target.artist}</div>
                                    </div>
                                </div>
                                }
                            </TableCell>
                            <TableCell align="right">
                                <Button variant="outlined" color="primary" onClick={() => onEdit(song.source)}>
                                    Change
                                </Button>
                                <Button variant="outlined" color={isIgnored(song.source.id)? "success":"error"} onClick={() => onIgnore(song.source, isIgnored(song.source.id))} style={{ marginLeft: 8 }}>
                                    {isIgnored(song.source.id) ? "Include" : "Ignore"}
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>


        <Modal
            open={searchModalOpen}
            onClose={handleSearchModalClose}
            aria-labelledby="scrollable-modal-title"
            aria-describedby="scrollable-modal-description"
        >
            <Box sx={modalStyle}>
            <SearchMTSongModal sourceSongId={currentlySearchingSong} handleSearchModalCloseWithSelection={handleSearchModalCloseWithSelection} handleSearchModalClose={handleSearchModalClose} accountType={loadedSongs.destAccountType} isYTMusic={loadedSongs.isDestYTMusic} accountId={loadedSongs.destinationCaId}/>
            </Box>
        </Modal>
        
        
        <Box sx={{pt:"1rem"}}>
        
        <Stack direction="row" spacing={2}>
            <Button variant="outlined" startIcon={<CancelIcon />} onClick={() => { window.location.href = config.accountsPath }}>
                Cancel
            </Button>
            <Button variant="contained" endIcon={isStartingTransfer ? <CircularProgress />:<SendIcon />} onClick={() => { startTransfer() }} disabled={isStartingTransfer}>
                {isStartingTransfer ? "Saving" : "Save Songs"}
            </Button>
        </Stack>
        
         {  errorSaving === "" ? <></> : <Alert  severity="error" icon={false} sx={{ m: 1 }}  >{errorSaving}</Alert>}
        </Box>

    </Paper>
    
</Grid>
)
}


  return (
    
    <>
    {loading ? <CircularProgress />
        : <> {  errorLoading === "" ? <>{mTTable()}</> : <Alert  severity="error" icon={false} sx={{ m: 1 }}  >{errorLoading}</Alert>}

        </>
    }
</>
  );

};


import {React, useEffect, useState} from 'react';
import config from '../config';
import { useNavigate } from 'react-router-dom';

const StripeCallback = () => {
    const [status, setStatus] = useState(null);
    const [customerEmail, setCustomerEmail] = useState('');
    const navigate = useNavigate();
    const queryString = window.location.search
    const params = new URLSearchParams(queryString);

    useEffect(() => {
      const queryString = window.location.search;
      const apiUrl = `${config.apiUrl}/stripe/handleSession?sessionId=${params.get('session_id')}`;
      const user = JSON.parse(localStorage.getItem('user'));

      fetch(apiUrl, {   
          headers: {
            'Authorization': 'Bearer ' + user.accessToken,
        }
      })
        .then((res) => res.json())
        .then((data) => {
          setStatus(data.status);
        });
    }, []);
  
    if (status === 'open') {
      return (
        navigate(config.dashboardPath+"/premium?message=PAYMENT_NOT_COMPLETE")
      )
    }
  
    if (status === 'complete') {
      const user = JSON.parse(localStorage.getItem('user'));
      const premiumUser = {...user}
      premiumUser["premium"] = true
      localStorage.setItem('user', JSON.stringify(premiumUser))
      return (
        navigate(config.dashboardPath+"/premium?message=PAYMENT_COMPLETE")
      )
    }
  
    return (
      <>
      </>
    );
  }
  
  export default StripeCallback;
import {useEffect, useState} from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Image from 'material-ui-image'
import { useNavigate, redirect } from 'react-router-dom';
import { Alert } from '@mui/material';

import config from '../config';

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://musicporter.com/">
                Music Porter
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function SignIn() {

    const navigate = useNavigate();

    const [errors, setErrors] = useState({});


    const [errResponse, setErrResponse] = useState("");

    const [formData, setFormData] = useState({
        email: ''
    });


    const validate = (submittedFormData) => {
        let errors = {};

        if (!submittedFormData.email) {
            errors.email = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(submittedFormData.email)) {
            errors.email = "Email address is invalid";
        }

        return errors;
    };


    const handleSubmit = (e) => {
        e.preventDefault();
        var formDataObj = new FormData(e.currentTarget);
        var newData = {
            email: formDataObj.get('email')
        }
        const validationErrors = validate(newData);
        setFormData(newData)
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            setErrors({});
            // Handle form submission (e.g., send data to backend)
            fetch(config.apiUrl + "/user/resetPassword", { // Replace with your actual endpoint
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(newData)
            })
                .then(response => {
                    if (!response.ok) {
                        if (response.status === 400) {
                            return response.text().then(text => {
                                throw new Error(text || 'Something went wrong');
                            });
                        }
                        throw new Error('Sorry, some error occured. Please try again later or let us know');
                    }
                    return response;
                })
                .then(data => {
                    setErrResponse("Please check your inbox for password reset link.")
                    setFormData({})
                    setErrors({});
                })
                .catch((error) => {
                    setErrResponse(error.message)
                    setErrors({});
                    console.error('Error:', error);
                });
        }

    };

    
    return (
        <ThemeProvider theme={defaultTheme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Box
                    component="img"
                    sx={{
                        width: 350
                    }}
                    alt="Music Porter logo."
                    src="https://musicporter.com/wp-content/uploads/2024/05/music-porter-high-resolution-logo-transparent-1.png?auto=format&w=350&dpr=2"
                    />
                    

                    <Typography component="h1" variant="h5" mt={5}>
                        Reset Password
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            name="email"
                            label="Email Address"
                            autoFocus
                        />
                        {errors.email && <Alert  severity="error" icon={false} >{errors.email}</Alert>}


                        {errResponse && <Alert  severity="error" icon={false} >{errResponse}</Alert>}

                        {/* <FormControlLabel
                            control={<Checkbox value="remember" color="primary" />}
                            label="Remember me"
                        /> */}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Reset Password
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link href="login" variant="body2">
                                    Back to Login
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link href="signup" variant="body2">
                                    {"Don't have an account? Sign Up"}
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <Copyright sx={{ mt: 8, mb: 4 }} />
            </Container>
        </ThemeProvider>
    );
}
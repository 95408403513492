import  React, {useState, useEffect, useCallback} from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import { Container, Grid, Paper, Typography, Button, List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import config from '../../config';

import {loadStripe} from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from "react-router-dom";
import { format } from 'date-fns';
import CircularProgress from '@mui/material/CircularProgress';
import { Alert } from '@mui/material';

import PremiumIcon from '../../assets/premium.png'

const stripePromise = loadStripe(config.stripePublicKey);


export default function PremiumOptions() {
    const [openMonth, setMonthOpen] = React.useState(false);
    const handleMonthOpen = () => setMonthOpen(true);
    const handleMonthClose = () => setMonthOpen(false);

    const [openLifetime, setLifetimeOpen] = React.useState(false);
    const handleLifetimeOpen = () => setLifetimeOpen(true);
    const handleLifetimeClose = () => setLifetimeOpen(false);

    const [loading, setLoading] = useState(true); // State to track loading state
    const [tier, setTier] = useState(''); // Tier can be 'Free' or 'Premium'
    const [premiumExpiresOn, setPremiumExpiresOn] = useState('')


    const params = new URLSearchParams(window.location.search);
    const [redirectMessage, setRedirectMessage] = useState(params.get('message'))


  useEffect(() => {
    const fetchData = async () => {
        try {
            setLoading(true); // Set loading state to true
            const response = await fetch(config.apiUrl + "/user", {
                headers: {
                    'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('user')).accessToken,
                }
            });

            const status = await response.status;
            if (status === 200) {
                let data = await response.json()
                setTier(data.premium ? 'Premium' : 'Free')
                if(data.premium) {
                  setPremiumExpiresOn(data.payment.expiryDate)
                }
            } else {
                throw new Error('Failed to get profile');
            }

        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false)
        } finally {
            setLoading(false); // Set loading state to false after fetch completes
            
        }
    };

    fetchData();
}, []);



    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      };

    
      function CheckoutForm (stripePriceType)  {
        const fetchClientSecret = useCallback(() => {
          // Create a Checkout Session
          return fetch(config.apiUrl + "/stripe/createSession?stripePriceType="+stripePriceType, {
            method: "GET",
            headers: {
              'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('user')).accessToken,
              'Content-Type': 'application/json'
            },
          })
            .then((res) => {
                return res.json()
            })
            .then((data) => data.stripeSession.clientSecret)
            .catch((error) => {
                console.error('Error:', error);
            });
        }, []);
      
        const options = {fetchClientSecret};
      
        return (
          <div id="checkout">
            <EmbeddedCheckoutProvider
              stripe={stripePromise}
              options={options}
            >
              <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>
          </div>
        )
      }


      function premiumSubscriptionNotice  ( endDate ) {
        const formattedDate = format(new Date(endDate), 'MMMM do, yyyy, h:mm a');
      
        return (
          <Container maxWidth="sm" style={{ padding: '20px', backgroundColor: '#f5f5f5', borderRadius: '8px', textAlign: 'center' }}>
            <Typography variant="h6" gutterBottom>
              Premium Subscription Active
            </Typography>
            <Typography variant="body1">
              Your premium subscription is active until {formattedDate}.
            </Typography>
          </Container>
        );
      }

      function featuresView(disablePurchase) {
        return (
          <Container>
          <Typography variant="h4" component="h2" gutterBottom align="center">
            Premium Options
          </Typography>
          <Grid container spacing={4} justifyContent="center">
            {/* One Month Plan */}
            <Grid item xs={12} sm={6} md={4}>
              <Paper elevation={3} sx={{ padding: 2 }}>
                <Typography variant="h5" component="h3" align="center" gutterBottom>
                  One month (30 days)
                </Typography>
                <Typography variant="h4" align="center">
                  $1.99 one-time
                </Typography>
                <Box display="flex" justifyContent="center" my={2}>
                  <img
                    src={PremiumIcon} // Replace with your image URL
                    alt="Diamond"
                    style={{ width: '100px', height: '100px' }}
                  />
                </Box>
                <List>
                  <ListItem>
                    <ListItemIcon>
                      <CheckCircleIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText primary="Transfer unlimited tracks/albums" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <CheckCircleIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText primary="Verify and Transfer (coming soon)" ></ListItemText> 
                  </ListItem>

                  <ListItem>
                    <ListItemIcon>
                      <CheckCircleIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText primary="No monthly subsciption" />
                  </ListItem>
                </List>
                <Button 
                  variant="contained" 
                  color="primary" 
                  fullWidth 
                  sx={{ marginTop: 2, marginBottom: 2 }}
                  onClick={handleMonthOpen}
                  disabled={disablePurchase}
                >
                  Choose Plan
                </Button>
              </Paper>
            </Grid>
            
            {/* Lifetime Plan */}
            <Grid item xs={12} sm={6} md={4}>
              <Paper elevation={3} sx={{ padding: 2 }}>
                <Typography variant="h5" component="h3" align="center" gutterBottom>
                  Lifetime Plan
                </Typography>
                <Typography variant="h4" align="center">
                  $29.99 one-time
                </Typography>
                <Box display="flex" justifyContent="center" my={2}>
                  <img
                    src={PremiumIcon} // Replace with your image URL
                    alt="Diamond"
                    style={{ width: '100px', height: '100px' }}
                  />
                </Box>
                <List>
                  <ListItem>
                    <ListItemIcon>
                      <CheckCircleIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText primary="Transfer unlimited tracks/albums" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <CheckCircleIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText primary="Verify and Transfer (coming soon)" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <CheckCircleIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText primary="Lifetime access to all our upcoming features" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <CheckCircleIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText primary="Pay once, free forever" />
                  </ListItem>
                </List>
                <Button 
                  variant="contained" 
                  color="primary" 
                  fullWidth 
                  sx={{ marginTop: 2, marginBottom: 2 }}
                  onClick={handleLifetimeOpen}
                  disabled={disablePurchase}
                >
                  Choose Plan
                </Button>
              </Paper>
            </Grid>
          </Grid>
    
    
    
          <Modal
            open={openMonth}
            onClose={handleMonthClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
                {CheckoutForm("MONTH")}
            </Box>
          </Modal>
    
    
    
          <Modal
            open={openLifetime}
            onClose={handleLifetimeClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
                {CheckoutForm("LIFETIME")}
            </Box>
          </Modal>
        </Container>
        )
      }

    return(
        <>
      <Container>
        {redirectMessage === "PAYMENT_NOT_COMPLETE" ? <Alert sx={{ mt: 2, mb: 2 }} severity="error" icon={false} >Payment not complete. Please try again or contact us.</Alert> : <></>}
        {redirectMessage === "PAYMENT_COMPLETE" ? <Alert sx={{ mt: 2, mb: 2 }} severity="success" icon={false} >Thank you. You are now a premium user.</Alert> : <></>}  
        </Container>
      


        {loading ? <>
          <CircularProgress />
          {featuresView(true)}
          </> : 
          
            tier === 'Free' ? 
            <>
              {featuresView(false)}
            </> 
            :
            <>
              {premiumSubscriptionNotice(premiumExpiresOn)}
              {featuresView(true)}
            </>
        }


        </>
    )
}
import { useEffect, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Image from 'material-ui-image'
import { useNavigate, redirect } from 'react-router-dom';
import { Alert } from '@mui/material';
import { useGoogleLogin } from '@react-oauth/google';
import GoogleButton from 'react-google-button'
import {Terms} from './signup'

import config from '../config';

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://musicporter.com/">
                Music Porter
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function SignIn() {

    const navigate = useNavigate();

    const [errors, setErrors] = useState({});

    const [errResponse, setErrResponse] = useState("");

    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });

    const params = new URLSearchParams(window.location.search);
    const [redirectMessage, setRedirectMessage] = useState(params.get('message'))


    useEffect(() => {

        if (localStorage.getItem("user") != null) {
            //Validate user token and route to dashboard, else clean usera

            const validateSession = async () => {
                try {
                    const response = await fetch(config.apiUrl + '/user/validateSession', {
                        headers: {
                            'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('user')).accessToken,
                        }
                    });
                    if (!response.ok) {
                        localStorage.removeItem('user');
                        localStorage.removeItem('logggedInAt');
                        throw new Error('Invalid token');
                    } else {
                        navigate(config.accountsPath);
                    }

                } catch (error) {

                }
            };


            validateSession();
        }
    }, []);

 

    const validate = (submittedFormData) => {
        let errors = {};


        if (!submittedFormData.email) {
            errors.email = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(submittedFormData.email)) {
            errors.email = "Email address is invalid";
        }

        if (!submittedFormData.password) {
            errors.password = "Password is required";
        }

        return errors;
    };


    const handleSubmit = (e) => {
        e.preventDefault();

        var formDataObj = new FormData(e.currentTarget);
        var newData = {
            email: formDataObj.get('email'),
            password: formDataObj.get('password')
        }

        const validationErrors = validate(newData);

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            setErrors({});
            // Handle form submission (e.g., send data to backend)
            fetch(config.apiUrl + "/user/login", { // Replace with your actual endpoint
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(newData)
            })
                .then(response => {
                    if (!response.ok) {
                        if (response.status === 400) {
                            return response.text().then(text => {
                                throw new Error(text || 'Something went wrong');
                            });
                        }
                        throw new Error('Sorry, some error occured. Please try again later or let us know');
                    }
                    return response.json();
                })
                .then(data => {
                    localStorage.setItem('user', JSON.stringify(data));
                    localStorage.setItem('logggedInAt', new Date().toISOString());
                    navigate(config.accountsPath)
                })
                .catch((error) => {
                    setErrResponse(error.message)
                    setErrors({});
                    console.error('Error:', error);
                });
        }

    };

    const googleLogin = useGoogleLogin({
        onSuccess: async tokenResponse => {
            navigate("/login/google/authenticate?code=" + tokenResponse.access_token)
        },
        // flow: 'implicit', // implicit is the default
    });

    return (
        <ThemeProvider theme={defaultTheme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                

                    <Box
                        component="img"
                        sx={{
                            width: 350
                        }}
                        alt="Music Porter logo."
                        src="https://musicporter.com/wp-content/uploads/2024/05/music-porter-high-resolution-logo-transparent-1.png?auto=format&w=350&dpr=2"
                        mb={5}
                        />


                    <GoogleButton onClick={() => googleLogin()} label="Login with Google"></GoogleButton>
                    {redirectMessage === "GAUTH_ERROR" ? <Alert sx={{ mt: 2, mb: 2 }} severity="error" icon={false} >Failed to login with google please try again later or contact us.</Alert> : <></>}


                    <Typography component="h1" variant="h5" >
                        or
                    </Typography>
                    <Typography component="h1" variant="h5">
                        Sign in with password
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoFocus
                        />
                        {errors.email && <Alert severity="error" icon={false} >{errors.email}</Alert>}

                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                        />
                        {errors.password && <Alert severity="error" icon={false} >{errors.password}</Alert>}

                        {errResponse && <Alert severity="error" icon={false} >{errResponse}</Alert>}

                        {redirectMessage === "PASSWORD_UPDATED" ? <Alert sx={{ mt: 2, mb: 2 }} severity="success" icon={false} >Please login with new password.</Alert> : <></>}


                        {/* <FormControlLabel
                            control={<Checkbox value="remember" color="primary" />}
                            label="Remember me"
                        /> */}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Sign In
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link href="forgotPassword" variant="body2">
                                    Forgot password?
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link href="signup" variant="body2">
                                    {"Don't have an account? Sign Up"}
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <Terms sx={{ mt: 5 }} />
                <Copyright sx={{ mt: 2 }} />
            </Container>
        </ThemeProvider>
    );
}
import React, { useState, useEffect } from 'react';
import config from '../../config';
import AccountsDetails from '../transfer/accountsDetails';

import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

export default function Backup() {
  const [loading, setLoading] = useState(false);
  const [hasEnoughAccounts, setHasEnoughAccounts] = useState(false);
  const [connectedAccounts, setConnectedAccounts] = useState(null)
  const [selectedAccount, setSelectedAccount] = useState('')
  const [backupHistory, setBackupHistory] = useState([])


  ///Get accounts
  useEffect(() => {
    const fetchHistory = async () => {
      if(selectedAccount === '') {
        setBackupHistory([])
        return
      }
      try {
        setLoading(true); // Set loading state to true
        const user = JSON.parse(localStorage.getItem('user'));
        const response = await fetch(config.apiUrl + "/snapshot/history?selectedConnectedAccount=" + selectedAccount, {
          headers: {
            'Authorization': 'Bearer ' + user.accessToken,
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        setConnectedAccounts(data)
        if (data.length > 0) {
          setHasEnoughAccounts(true)
        }

      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false); // Set loading state to false after fetch completes
      }
    };

     fetchHistory(); // Call fetchData function when component mounts
  }, [selectedAccount]); // Empty dependency array ensures useEffect runs only once




  return (
    <>
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                        
        <Typography component="h2" variant="h6" color="primary" gutterBottom>
            Your Backups
          </Typography>



              <AccountsDetails selectedAccount={selectedAccount} onSelectedAccountChange={setSelectedAccount} setConnectedAccounts={setConnectedAccounts} defaultSelectText="Filter by Accounts"></AccountsDetails>
              {selectedAccount === '' ?
                <Typography variant="caption" display="block" gutterBottom></Typography>
                : <></>
              }


              //show all the history  



        </Paper>
      </Grid>

    </>
  );
};

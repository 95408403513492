import './App.css';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import Login from './auth/login.js';
import Signup from './auth/signup';
import Dashboard from './dashboard/dashboard';
import GoogleLoginCallback from './auth/googleLoginCallback'
import RedirectUrl  from './helpers/redirectUrl';
import { generateRandomString } from './helpers/generateRandomString';
// import SpotifyHome from './dashboard/spotify/spotifyHome'
import SpotifyCallback from './dashboard/spotify/spotifyCallback'
import AmazonMusicCallback from './dashboard/amazonMusic/amazonMusicCallback'
import TidalCallback from './dashboard/tidal/tidalCallback'
import YouTubeCallback from './dashboard/youtube/youtubeCallback'
import YoutubeMusicCallback from './dashboard/youtube/youtubeMusicCallback'
import config from './config';
import EmailHandler from './email/emailHandler';
import ForgotPassword from './auth/forgotPassword'
import ResetPassword from './auth/resetPassword'
import StripeCallback from './callback/stripeCallback'
import RedirectTidal from './helpers/redirectTidal'


function App() {

  const APP_URL = config.appUrl;


  const SPOTIFY_LOGIN_URL = "https://accounts.spotify.com/authorize"
  + "?response_type=code"
  + "&client_id=5bf112fd22d14fac8256db5402961f24"
  + "&scope=playlist-read-private playlist-read-collaborative playlist-modify-private playlist-modify-public user-library-modify user-library-read"
  + "&redirect_uri=" + APP_URL + "/connect/spotify/authenticate"
  + "&state=" + generateRandomString(16)


  const AMAZON_MUSIC_URL = "https://www.amazon.com/ap/oa"
  + "?response_type=code"
  + "&client_id=amzn1.application-oa2-client.024b8f0945da477a83fa0f1a828c8a53"
  + "&scope=profile music::library"
  + "&redirect_uri=" + APP_URL + "/connect/amazonMusic/authenticate"
  + "&state=" + generateRandomString(16)


  const YOUTUBE_LOGIN_URL = "https://accounts.google.com/o/oauth2/v2/auth"
  + "?response_type=code"
  + "&client_id="+config.googleAuthClientId
  + "&scope=https://www.googleapis.com/auth/youtube"
  + "&redirect_uri=" + APP_URL + "/connect/youtube/authenticate"
  + "&state=" + generateRandomString(16)
  +"&access_type=offline"
  +"&prompt=consent"
  

  const YOUTUBE_MUSIC_LOGIN_URL = "https://accounts.google.com/o/oauth2/v2/auth"
  + "?response_type=code"
  + "&client_id="+config.googleAuthClientId
  + "&scope=https://www.googleapis.com/auth/youtube"
  + "&redirect_uri=" + APP_URL + "/connect/youtubeMusic/authenticate"
  + "&state=" + generateRandomString(16)
  +"&access_type=offline"
  +"&prompt=consent"
  


  //TODO: show icloud enable music library


   return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Login/>}/>
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path='/dashboard/*' element={<Dashboard />} />
        
        <Route path='/dashboard' element={<RedirectUrl url={"/dashboard/accounts"} />} />
                      
        <Route path='/login/google/authenticate' element={<GoogleLoginCallback/>} />


        <Route path='/connect/spotify' element={<RedirectUrl url={SPOTIFY_LOGIN_URL} />} />
        <Route path='/connect/spotify/authenticate' element={<SpotifyCallback/>} /> 


        <Route path='/connect/tidal' element={<RedirectTidal />} />
        <Route path='/connect/tidal/authenticate' element={<TidalCallback/>} /> 

        <Route path='/connect/amazonMusic' element={<RedirectUrl url={AMAZON_MUSIC_URL} />} />
        <Route path='/connect/amazonMusic/authenticate' element={<AmazonMusicCallback/>} /> 

        <Route path='/emailOutbound/:userId/activateAccount/:token' element={<EmailHandler action="activateAccount" />}/>
        <Route path='/emailOutbound/:userId/updatePassword/:token' element={<EmailHandler action="updatePassword"/>}/>
        <Route path='/emailOutbound/dashboard/transferHistory' element={<RedirectUrl url={config.transferHistoryPath}/>}/>

        <Route path='/forgotPassword' element={<ForgotPassword/>} />
        <Route path='/resetPassword/:userId/:token' element={<ResetPassword/>} />

        <Route path='/connect/youtube' element={<RedirectUrl url={YOUTUBE_LOGIN_URL} />} />
        <Route path='/connect/youtube/authenticate' element={<YouTubeCallback/>} />

        <Route path='/connect/youtubeMusic' element={<RedirectUrl url={YOUTUBE_MUSIC_LOGIN_URL} />} />
        <Route path='/connect/youtubeMusic/authenticate' element={<YoutubeMusicCallback/>} />

        <Route path="/stripe/session/callback" element={<StripeCallback></StripeCallback>} />
      </Routes>
    </BrowserRouter>
  )
}

export default App;
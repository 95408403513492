import React from 'react';

const SyncMain = () => {
  return (
    <>
        
    </>
  );
};

export default SyncMain;